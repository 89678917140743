import React, { Component } from 'react'
import GalleryIcopurple from "../assets/images/history-img.png";
import LandingPageImg from "../assets/images/pflanding.png"
import ListingFooter from "../Common/ListingFooter";
import ReactPlayer from "react-player";
import AboutHeader from '../About/AboutHeader'
import FaqPage from './FaqPage';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import ReactGA from 'react-ga';
import hawksoft from "../assets/images/new-images/hawksoftimg.jpg";
import Vertafore from "../assets/images/new-images/vertaforeimage.jpg";
import Nowcert from "../assets/images/new-images/nowcertimg.png";
import BetterAgency from "../assets/images/new-images/BatteryAgency.jpg";
import FinancePartners from "../assets/images/new-images/gotoPremiumFinance.jpg";
import { browserName, browserVersion, CustomView } from "react-device-detect";

export default class PfAbout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMuted: true,
        };
    }
    onVideoPuase = () => {
        this.setState({ isMuted: true });
    }

    onGetDemoClick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Request Demo Page',
            action: 'Click on Request Demo  button from  PF about page',
        });
        this.props.history.push("/requestdemo");
    }
    onLogoClick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Landing page',
            action: 'Click on logo  from PF about page',
        });
        this.props.history.push("/");
    };
    onRegisterclick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Register page',
            action: 'Click on register button from PF about page',
        });
        this.props.history.push("/agencyonboarding/hawksoft?utm_source=PF_about_page");
    };
    onAddCromeExtensionClick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'PF about page',
            action: 'Click on download chrome extension button from PF about page',
        });
        window.open("https://chrome.google.com/webstore/detail/insurancegig/pidaodnmlepjkmkldnfommgpckflndfg", "_blank")
    }
    render() {
        return (
            <div className="pf-about-main">
                <div className="meet-again">

                    <div className="meet-again-main meet-again-main2 premfi">
                        <div className="container-fluid">
                            <AboutHeader onLogoClick={this.onLogoClick}></AboutHeader>
                            <div className="container ">
                                <div className="meet-again-width">
                                    <div className="row align-items-center ">
                                        <div className="col-md-6 z-index-1 gigmarket-height ">

                                            <div className="first-div-pf-about">
                                                <h2 className=''>Introducing PremFi by InsuranceGIG!
                                                </h2>
                                                <p>
                                                    The EASIEST way for agents  to quote  Premium Finance!
                                                </p>
                                                No More…
                                                <ul>
                                                    <li>Leaving the AMS
                                                    </li>
                                                    <li>
                                                        Duplicate data entry
                                                    </li>
                                                    <li>Logging in to separate banking portals</li>
                                                </ul>
                                                <div className="mt-5 about-mob-btn  pf-reg-chrome-btn d-flex">
                                                    <button
                                                        className="btn about-primary-btn-purple mobpremfi"
                                                        onClick={this.onRegisterclick}
                                                    >
                                                        Register
                                                    </button>

                                                    <CustomView condition={browserName === "Chrome" || browserName === "Edge" || browserName === "Safari" || browserName === "Firefox"}>
                                                        <button
                                                            className="btn btn-primary-outline-about premfichrom mob-req-btn mob-view h-auto"
                                                            onClick={() => this.onAddCromeExtensionClick()}
                                                        >
                                                            {browserName === "Chrome" && "Download Chrome Extension"}
                                                            {browserName === "Edge" && "Download Edge Extension"}
                                                            {browserName === "Safari" && "Download Safari Extension"}
                                                            {browserName === "Firefox" && "Download Firefox Extension"}
                                                        </button>
                                                    </CustomView>



                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 text-center z-index-1 welcom-img about-video ">
                                            <div className="video-main ">
                                                <ReactPlayer
                                                    muted={this.state.isMuted ? true : false}
                                                    className="about-third-video react-video"
                                                    url={"https://youtu.be/b6-gSTd4l_0"}
                                                    playing={true}
                                                    loop={true}
                                                    controls={true}
                                                    onPause={this.onVideoPuase}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-purple-about-img text-white">
                        <div className="container-fluid meet-again-middle">
                            <div className="container">
                                <div className=" row mb-4 align-items-center">
                                    <div className="col-md-6">

                                        <div className="text-white mb-3 mob-font premfi text-18 lh-160 ">
                                            With PremFi, powered by InsuranceGIG, you’ll experience:
                                        </div>
                                        <ul className="text-16 text-white lh-160 ">
                                            <li>
                                                Work directly in your AMS
                                            </li>
                                            <li>
                                                Gain access to multiple Finance Partners
                                            </li>
                                            <li>
                                                Connects all parties in the Premium Finance journey
                                            </li>
                                            <li>
                                                Additional revenue streams
                                            </li>
                                        </ul>
                                        <div className="text-white mb-3 mob-font premfi text-18">
                                            PremFi is available at NO COST to agents!
                                        </div>

                                        {/* <div className="text-white mb-3 mob-font text-16">
                                            Contact
                                            <a href="mailto:sales@insuranceGIG.com"> sales@insuranceGIG.com</a>,     if you have any questions.
                                        </div> */}

                                    </div>
                                    <div className="col-md-3 col-6 mt-2 text-center">

                                        <img
                                            src={LandingPageImg}
                                            className="img-fluid history-img "
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 mt-2 text-center">
                                        <img
                                            src={GalleryIcopurple}
                                            className="img-fluid history-img "
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="network-effects text-center">
                        <div className="container pt-5 pb-5">

                            <div className="row  align-items-center">
                                <div className="col-md-12 z-index-1">
                                    <h2 className="font-weight-bold faq-title mb-4">
                                        PremFi Partners
                                    </h2>
                                </div>
                                {/* <FaqPage></FaqPage> */}
                                <div className="col-md-12 company-name-text">
                                    <div className='row  d-flex justify-content-center align-items-center'>
                                        {/* <div className="col-1"></div> */}
                                        <div className="col-2">
                                            <img
                                                src={hawksoft}
                                                alt=""
                                                className="img-fluid app-icons premficon"
                                                data-toggle="tooltip" data-placement="bottom"
                                            />
                                            <span className='d-block  mt-1 '>HawkSoft</span>
                                        </div>
                                        <div className="col-2">
                                            <img
                                                src={Vertafore}
                                                alt=""
                                                className="img-fluid app-icons premficon"
                                                data-toggle="tooltip" data-placement="bottom"
                                            />
                                            <span className='d-block  mt-1 '>Vertafore</span>
                                        </div>

                                        <div className="col-2">
                                            <img
                                                src={Nowcert}
                                                alt=""
                                                className="img-fluid app-icons premficon"
                                                data-toggle="tooltip" data-placement="bottom"
                                            />
                                            <span className='d-block  mt-1 '>NowCerts</span>
                                        </div>

                                        {/* <div className="col-2">
                                            <img
                                                src={BetterAgency}
                                                alt=""
                                                className="img-fluid app-icons premficon"
                                                data-toggle="tooltip" data-placement="bottom"
                                            />
                                            <span className='d-block  mt-1 ' >BetterAgency</span>

                                        </div> */}

                                        <div className="col-3">
                                            <img
                                                src={FinancePartners}
                                                alt=""
                                                className="img-fluid app-icons premficon financepartnerts"
                                                data-toggle="tooltip" data-placement="bottom"
                                            />
                                            <span className='d-block mt-1 '>gotoPremiumFinance</span>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <ListingFooter></ListingFooter>
                </div>
            </div>
        )
    }
}
