import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import semseeLogo from '../../assets/images/SemseeLogo.png';
import MainHeader from '../../OtherCommonPages/MainHeader'
import ListingFooter from '../../Common/ListingFooter';
export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

  
    render() {
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <MainHeader
                    showSidebar={this.state.showSidebar}
                    pageName="Workflow"
                ></MainHeader>

                <div className="bg-community-banner">
                    <div class="mid-container1">
                    <div className='row justify-content-start text-center'>
                        <span className='pt-5 z-index-1 '>
                            <h2 className="text-white text-start">  <img src={semseeLogo} height="55px" className='mr-2' style={{ borderRadius: "5px" }}></img>Semsee | One App. Many Quotes.</h2>
                          
                            <div className="bb-feedback-button video-btn NAICS_Help_Btn">

                            </div>
                        </span>
                    </div>
                    </div>
                </div>
                <div className="mid-container1 leads-card semsee-info-card hide-sorting datatable-leads dashboard-min-h card-info">
                    <div className="card">
                        <div className="card-body p-2">
                            <div className='SemseeInfo'>
                                Oops it doesn't look like you are a Semsee user yet. Please contact your Semsee Admin user to add you today. Feel free to contact <a className='SemseeInfoLink text-secondary-color' href="mailto: support@semsee.com">support@semsee.com</a> with any questions.
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop:"16%"}}>
                <ListingFooter></ListingFooter></div>
            </div >
            
        )
    }
}