
import React, { Component } from 'react';

class ServiceDetailDescription extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="card">
                <div className="card-body gig-services ">
                    <h4 className="font-weight-600">Overview</h4>
                    <p dangerouslySetInnerHTML={{ __html: this.props.service.description }}>
                    </p>
                </div>
            </div>
        );

    }
}

export default ServiceDetailDescription;
