import React, { Component } from 'react';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';

import imgFront from '../assets/images/thank-you-front.png';
import imgBack from '../assets/images/thank-you-back.png';
import { Dialog } from 'primereact/dialog';


class LOB extends Component {

    constructor(props) {
        super(props);
        this.state = {

            errors: {},
            loading: false,
            saveMsg: "",
            postMsg: "",
            errorBorderColorUsername: false,
            errorBorderColorPassword: false,
            showPreviewCard: false

        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (this.state.saveMsg.trim() == "") {
            formIsValid = false;
            errors["saveMsg"] = "Message is required.";

            this.setState({
                errorBorderColorPassword: true
            })
        }
        else {
            this.setState({
                errorBorderColorPassword: false
            })
        }



        this.setState({ errors: errors });
        return formIsValid;
    }

    componentDidMount = () => {

        //  this.getTryoutDataBYServiceId(this.props.serviceId);

    }

    onSaveClick = (e) => {

        if (!this.handleValidation()) {
            return false;
        }
        this.setState({
            loading: true,
            // saveMsg:"",
            postMsg: this.state.saveMsg
        });

        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);

        // setTimeout(() => {
        //     this.setState({ saveMsg:"" });
        // }, 2000);

    };


    onKeyDown = (e) => {
        if (e.keyCode == 13) {
            this.onSaveClick();
        }
    };

    onChange = (e) => {

        this.setState({

            [e.target.name]: e.target.value,

        });
    };

    onClickTextBox = (e) => {

        document.getElementById("exampleFormControlTextarea1").placeholder = ""
    };
    onPreviewClick = () => {
        this.setState({
            showPreviewCard: !this.state.showPreviewCard
        })
    }
    onHide = () => {
        this.setState({
            showPreviewCard: false
        })
    }

    render() {
        return (
            <div>
                <div className="wrapper">

                    <div className=" postcard-top text-start card p-2">
                        <h4>Customize Your Postcard</h4>
                        <div className="row">
                            <div className="col-md-6">
                                <form className="postcard">
                                    <div className="postcard-body">
                                        <h5>Sender Information</h5>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput1">John Sender</label>
                                            <tr></tr>
                                            <label htmlFor="exampleFormControlInput1">123 Main Street</label>
                                            <tr></tr>
                                            <label htmlFor="exampleFormControlInput1">Anycity, NY 12345</label>
                                        </div>

                                    </div>
                                    <div className="row pt-4 align-items-center">
                                        <div className="form-group col-md-6">
                                            <label>Upload Your Recepients List <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="fname" />
                                        </div>
                                        <div className="col-md-6 submit-buttons-post mt-3">
                                            <button className="btn btn-muted " data-toggle="modal" type="button">Browse</button>
                                            <button data-toggle="tooltip" title="You can upload your recipient's file, and map fields" className="btn btn-muted ml-2" data-placement="bottom" type="button">Upload</button>

                                        </div>
                                    </div>
                                    <span className="text-muted text-14">(Include First Name, Last Name, Address 1, Address 2, City, State, Zip fields) </span>
                                    <span className="text-14 text-secondary d-block"> <a href className="text-secondary-color font-weight-bold">Download File Template</a></span>
                                    <div className="form-group pt-3 ">
                                        <label>Include Your Message <span className="text-danger">*</span></label>
                                        <textarea className="form-control text-muted-colorl"
                                            value={this.state.saveMsg}
                                            onKeyDown={this.onKeyDown}
                                            onChange={this.onChange}
                                            onClick={this.onClickTextBox}
                                            name="saveMsg"
                                            id="exampleFormControlTextarea1"
                                            placeholder="Type your message here... it will appear on the back of the card. (500 words max)"

                                        />
                                        <span className="text-muted text-14">(Make sure to include CTA to make your mailing a success) </span>
                                        <br></br>
                                        &nbsp;<span className="text-red Validations">{this.state.errors["saveMsg"]}</span>
                                    </div>


                                    <div className="submit-buttons-post d-flex align-items-center">
                                        <button onClick={() => this.onSaveClick()}
                                            className="btn btn-secondary ml-2" onKeyDown={this.onKeyDown} data-toggle="tooltip" data-placement="bottom" type="button"
                                        >Save</button>
                                        <div className='pl-5'>
                                            {
                                                this.state.loading ?
                                                    <>
                                                        <IgigLoadingIcon></IgigLoadingIcon>

                                                    </>
                                                    : null
                                            }
                                        </div>


                                    </div>

                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="postcard">
                                    <div className="form-group">
                                        <label>Pick your Postcard Template <span className="text-danger">*</span></label>
                                        <select className="form-control select-form-control">
                                            <option>Thank You</option>

                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-muted btn-rounded post-btn-size " data-toggle="modal" type="button" >6X4</button>
                                        <button className="btn btn-muted btn-rounded post-btn-size ml-2 " data-toggle="modal" type="button" >6X9</button>
                                    </div>
                                    <div className="form-group">
                                        <label>Front</label>
                                        <img src={imgFront} className="img-fluid" />
                                    </div>
                                    <div className="form-group">
                                        <label>Back</label>
                                        <img src={imgBack} className="img-fluid" />

                                    </div>
                                    <div className="submit-buttons-post float-right">
                                        {/* <button className="btn btn-secondary mr-1" data-toggle="modal" data-target="#mapYourField" type="button">Preview</button> */}
                                        <button className="btn btn-secondary mr-1" type="button" onClick={this.onPreviewClick}>Preview</button>

                                        <button title="Send this postcard to your recipient(s) list" data-toggle="modal" className="btn btn btn-muted" type="button">Submit</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="modal fade lob-modal" id="mapYourField" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-medium modal-postcard" role="document">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center">
                                <h5 className="tryit-title" id="exampleModalLabel" onClick={this.demo}>Postcard Preview</h5>
                            </div>
                            <button type="button " className="close btn-x" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>

                        
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Front</label>
                                    <img src={imgFront} className="img-fluid" />
                                </div>
                                <div className="form-group">
                                    <div >
                                        <label>Back</label>
                                    </div>
                                    <img src={imgBack} className="img-fluid" />
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                          
                        
                                            <br></br>
                                            <div>
                                            <p className="text-start">{this.state.postMsg}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Dialog header="Postcard Preview" className='' visible={this.state.showPreviewCard} style={{ width: '30vw' }} onHide={() => this.onHide()}>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Front</label>
                            <img src={imgFront} className="img-fluid" />
                        </div>
                        <div className="form-group">
                            <div >
                                <label>Back</label>
                            </div>
                            <img src={imgBack} className="img-fluid" />
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    {/* POST MSG */}

                                    <br></br>
                                    <div>
                                        <p className="text-start">{this.state.postMsg}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/* <form className="postcard  mb-3">
                                                <div className="postcard-body p-3">
                                                    <h5>Sender Information</h5>
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="exampleFormControlInput1">John Sender</label>
                                                        <tr></tr>
                                                        <label htmlFor="exampleFormControlInput1">123 Main Street</label>
                                                        <tr></tr>
                                                        <label htmlFor="exampleFormControlInput1">Anycity, NY 12345</label>
                                                    </div>
                                                </div>
                                            </form> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </Dialog>
            </div>

        );
    }
}
export default LOB;
