import React from "react";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "font-awesome/css/font-awesome.min.css";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "./assets/css/mymarketplacestyle.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "react-datepicker/dist/react-datepicker.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from "react-ga";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {
//   load as loadIntercom,
//   boot as bootIntercom,
//   update as updateIntercom,
// } from "./Common/intercom";

// **************Before login************

import ListingPage from "./ListingPage/ListingPage";
import CommonValues from "./Common/util";
import ListingDetails from "./ListingPage/ListingDetails";
import RequestService from "./ListingPage/RequestService";
import StartWorkflow from "./ListingPage/StartWorkflow";
import ErrorPage from "./Common/ErrorPage";
import { createBrowserHistory } from "history";
import ServicePreview from "./MyServices/ServicePreview";
import VertaforeContact from "./Vertafore/VertaforeContact";
import Info from "./RTG/Semsee/Info";
import Partner from "./AgencyAgentOnboarding/Partner";
import SellerReport from "./Report/SellerReport";
import About from "./About/About";

import Callback from "./Accounts/Callback";

import PfAbout from "./Premium Finance About/PfAbout";
import Settings from "./RTG/PremiumFinance/Settings";
import ChromeCallback from "./Accounts/ChromeCallback";
import NowcertsSso from "./Accounts/NowcertsSso";

const Dashboard = React.lazy(() => import("./Dashboard/Dashboard"));
const TermsAndConditions = React.lazy(() =>
  import("./Accounts/TermsAndConditions")
);
const CommunityDashboard = React.lazy(() =>
  import("./Community/CommunityDashboard")
);
const BlogArticle = React.lazy(() => import("./Community/BlogArticle"));
const ExploreBlogs = React.lazy(() => import("./Community/ExploreBlogs"));
const WorkflowDashboard = React.lazy(() =>
  import("./MyWorkflows/WorkflowDashboard")
);
const FeaturedWorkflows = React.lazy(() =>
  import("./MyWorkflows/FeaturedWorkflows")
);
const PrivacyPolicy = React.lazy(() => import("./Accounts/PrivacyPolicy"));
const Videos = React.lazy(() => import("./Video/Videos"));
const Login = React.lazy(() => import("./Accounts/Login"));
const PartnerLogin = React.lazy(() => import("./Accounts/PartnerLogin"));
const Register = React.lazy(() => import("./Accounts/Register"));
const NewRegistration = React.lazy(() => import("./Accounts/NewRegistration"));
const ResetPassword = React.lazy(() => import("./Accounts/ResetPassword"));
const ForgotPassword = React.lazy(() => import("./Accounts/ForgotPassword"));
const SetPassword = React.lazy(() => import("./Accounts/SetPassword"));
const CouncilTermsAndcondition = React.lazy(() => import("./Accounts/CouncilTermsAndcondition"));
const PremiumFinancePrivacyPolicy = React.lazy(() => import("./Accounts/PremiumFinancePrivacyPolicy"));
const GigcodeRegistration = React.lazy(() => import("./Accounts/GigcodeRegistration"))


// **************After login************
const MyWorkflows = React.lazy(() => import("./MyWorkflows/MyWorkflows"));
const StartWorkflowInt = React.lazy(() =>
  import("./MyWorkflows/StartWorkflowInt")
);
const MyCompany = React.lazy(() => import("./Organization/MyCompany"));
const AccountUsers = React.lazy(() => import("./MyUsers/AccountUsers"));
const AddUpdateService = React.lazy(() =>
  import("./MyServices/AddUpdateService")
);
const Reports = React.lazy(() => import("./Report/Reports"));
const MyServices = React.lazy(() => import("./MyServices/MyServices"));
const MyProfile = React.lazy(() => import("./MyProfile/MyProfile"));
const CriteriaForm = React.lazy(() =>
  import("./ProviderAgreementConsent/CriteriaForm")
);
const FeeForm = React.lazy(() => import("./ProviderAgreementConsent/FeeForm"));
const CFOForm = React.lazy(() => import("./ProviderAgreementConsent/CFOForm"));
const ProviderDashboard = React.lazy(() =>
  import("./ProviderAgreementConsent/ProviderDashboard")
);
const RegistrationForm = React.lazy(() =>
  import("./ProviderAgreementConsent/RegistrationForm")
);
const ServiceOpenAPISpec = React.lazy(() =>
  import("./ListingPage/ServiceOpenAPISpec")
);
const AddUser = React.lazy(() => import("./MyUsers/AddUser"));
const SubscribedService = React.lazy(() =>
  import("./SubscribedServices/SubscribedServices")
);
const SubscribedServiceDetails = React.lazy(() =>
  import("./SubscribedServices/SubscribeserviceDetails")
);
const Validate = React.lazy(() => import("./RTG/Merge.Dev/Validate"));
const MergeListingPage = React.lazy(() =>
  import("./RTG/Merge.Dev/ListingPage")
);
const Requestdemo = React.lazy(() => import("./MyWorkflows/Requestdemo"));
const RTGAdmin = React.lazy(() => import("./Common/admin"));
const UserOnboarding = React.lazy(() =>
  import("./AgencyAgentOnboarding/AgentOnboarding")
);
const AgencyOnboarding = React.lazy(() =>
  import("./AgencyAgentOnboarding/AgencyOnboarding")
);

const history = createBrowserHistory();

// history.listen((location) => {
//   updateIntercom();
// });
ReactGA.initialize("UA-209064119-3");

function App() {
  let path = window.location.href.split("agencyonboarding/")[1];
  let forgotpasswordpath = window.location.href.split("forgotpassword/")[1];
  let setpasswordpathwithtoken = window.location.href.split("setpassword/")[1];
  let setpasswordpath = "";
  if (
    setpasswordpathwithtoken != "" &&
    setpasswordpathwithtoken != null &&
    setpasswordpathwithtoken != undefined
  ) {
    setpasswordpath = setpasswordpathwithtoken.split("/")[1];
  }
  let privacypolicypath = window.location.href.split("privacypolicy/")[1];

  if (
    CommonValues.GetTenantId() == process.env.REACT_APP_NowCert_Tenant_Id ||
    (path && path.toLowerCase() == "nowcerts") ||
    (forgotpasswordpath && forgotpasswordpath.toLowerCase() == "nowcerts") ||
    (setpasswordpath && setpasswordpath.toLowerCase() == "nowcerts") ||
    (privacypolicypath && privacypolicypath.toLowerCase() == "nowcerts")
  ) {
    import("./assets/css/Nowcert.css");
  } else {
    import("./assets/css/appmarket.css");
  }

  // loadIntercom();
  // bootIntercom();

  return (
    <div>
      <Router>
        <Switch>
          {/* <Route path="/service/:index" component={ListingDetails}></Route> */}

          <Route path="/" component={Login} exact />

          {/* <Route path="/login/:index" component={PartnerLogin}></Route> */}

          <Route path="/login" component={Login}></Route>

          {/* <Route path="/register-seller" component={Register}></Route> */}

          {/* <Route path="/newregistration" component={NewRegistration}></Route> */}

          <Route path="/setpassword/:index" component={SetPassword}></Route>

          <Route path="/privacypolicy" component={PrivacyPolicy}></Route>

          <Route path="/premiumfinancepolicy" component={PremiumFinancePrivacyPolicy}></Route>

          <Route path="/designictermsandconditions" component={CouncilTermsAndcondition}></Route>

          {/* <Route path="/dashboard" component={Dashboard}></Route> */}

          <Route path="/forgotPassword" component={ForgotPassword}></Route>

          <Route path="/resetpassword/:index" component={ResetPassword}></Route>

          {/* <Route path="/company/:index" component={MyCompany}></Route> */}

          <Route path="/company" component={MyCompany}></Route>

          {/* <Route path="/requestservice" component={RequestService}></Route> */}

          {/* <Route path="/myservices" component={MyServices}></Route> */}

          {/* <Route path="/servicedetails/:index" component={AddUpdateService}></Route> */}

          {/* <Route path="/details" component={ServiceOpenAPISpec}></Route> */}

          {/* <Route path="/myworkflows" component={MyWorkflows}></Route> */}

          <Route path="/profile" component={MyProfile}></Route>

          <Route path="/terms" component={TermsAndConditions}></Route>

          {/* <Route path="/startworkflow/:index/:id" component={StartWorkflow} ></Route> */}

          {/* <Route path="/apidetails/:id" component={ServiceOpenAPISpec}></Route> */}

          {/* <Route path="/startworkflowint" component={StartWorkflowInt}></Route> */}

          {/* <Route path="/adduser" component={AddUser}></Route> */}

          {/* <Route path="/users" component={AccountUsers}></Route> */}

          <Route path="/reports" component={Reports}></Route>

          {/* <Route path="/sellerreport" component={SellerReport}></Route>

          <Route path="/criteriaform" component={CriteriaForm}></Route>

          <Route path="/feeform" component={FeeForm}></Route>

          <Route path="/cfoform" component={CFOForm}></Route>

          <Route path="/provider" component={ProviderDashboard}></Route>

          <Route path="/registrationform" component={RegistrationForm}></Route> */}
          {/* 
          <Route path="/community/:type" component={CommunityDashboard}></Route>

          <Route path="/blog/:id" component={BlogArticle}></Route>

          <Route path="/blogs" component={ExploreBlogs}></Route>

          <Route path="/workflowdashboard" component={WorkflowDashboard}></Route>

          <Route path="/videos" component={Videos}></Route>

          <Route path="/featuredworkflow" component={FeaturedWorkflows}></Route>

          <Route path="/servicepreview/:index" component={ServicePreview} ></Route> 

          <Route path="/vertafore" component={VertaforeContact}></Route>*/}

          <Route path="/:company/myservices" component={SubscribedService} exact ></Route>

          <Route
            path="/:company/service/:index/:from?" component={SubscribedServiceDetails}   ></Route>

          {/* <Route path="/semsee/info" component={Info}></Route>

          <Route path="/validate/:token" component={Validate} exact />

          <Route path="/merge.dev/linkgeneratorPOC" component={MergeListingPage} exact /> */}

          <Route path="/gigmarket/admin" component={RTGAdmin}></Route>
          <Route path="/agencyonboarding/gigcode" component={GigcodeRegistration} exact />

          {/* <Route path="/agencyonboarding/gigcode?code=:code" component={GigcodeRegistration} exact  ></Route> */}

          {/* <Route path="/agencyonboarding/gigcode/:index" component={GigcodeRegistration} exact  ></Route> */}

          <Route path="/agencyonboarding/:index?" component={AgencyOnboarding} exact  ></Route>

          <Route path="/agentonboarding/:index" component={UserOnboarding} exact  ></Route>

          {/* <Route path="/partner" component={Partner} exact></Route> */}

          <Route path="/about" component={About}></Route>

          <Route path="/premfi" component={PfAbout}></Route>


          <Route path="/requestdemo" component={Requestdemo} exact></Route>

          <Route path="/callback" component={Callback} exact></Route>
          <Route path="/pfsetting/:index" component={Settings} exact></Route>
          <Route path="/ChromeCallback" component={ChromeCallback} exact></Route>

          <Route path="/nowcertssso" component={NowcertsSso} exact  ></Route>

          <Route component={ErrorPage}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
