import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import copyToClipboard from '../assets/images/copy.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ListingFooter from '../Common/ListingFooter';
import MiddleHeader from '../Common/MiddleHeader';
import CommonValues from '../Common/util';
import MainHeader from '../OtherCommonPages/MainHeader';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';

class ChromeCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      registerURL: "",
      identityProviderError:""
    }


   
  }
  componentDidMount = () => {
    

     // Social Integration
     const query = new URLSearchParams(this.props.location.hash);
     if (query != null && query != "" && query != undefined) {
 
       var access_token = query.get("#access_token");
       var error_msg = query.get("error_description");
       if (access_token != "" && access_token != null && access_token != undefined) {
         var axios = require('axios');
         var config = {
           method: 'post',
           url: `${process.env.REACT_APP_Local_Url}/validateSocialLogin?`,
           headers: {
             'Content-Type': 'application/json',
             'Authorization': `Bearer ${access_token}`
           }
         };
         axios(config)
           .then((response) => {
 
             CommonValues.SetValues(
               response.data.token,
               response.data.userId,
               response.data.orgName,
               response.data.userName,
               response.data.roleId,
               response.data.avatarName,
               response.data.firstName,
               response.data.lastName,
               response.data.iconURL,
               response.data.hasGIGMarket,
               response.data.slugURL,
               response.data.headerText,
               response.data.tenantId
             );

             window.location.href = "/" + response.data.slugURL + "/service/" + process.env.REACT_APP_PF_ServiceId +"/chrome";

            })
           .catch((error) => {
             let errorMessage;
             if (error.response != null) {
               if (error.response.status === 401) {
                 //window.location.href = `${process.env.REACT_APP_UiUrl}/agencyonboarding#access_token=`+ access_token
                 errorMessage = "Unauthorized User!";
                 return false;
               }
               else {
                 errorMessage = "Unknown error while Sign-in!";
               }
             }
             else {
               errorMessage = "Unknown error while Sign-in!";
             }
             this.setState({
               errorMessage: errorMessage
             });
           });
       }
       else if(error_msg != "" && error_msg != null && error_msg != undefined)
       {
         this.setState({
          identityProviderError: error_msg
         });
       }
       else
       {
         this.setState({
           errorMessage: "Opp`s something went wrong"
         });
       }
     }

  }
  onLinkClick = () => {
    window.open(this.state.registerURL, "_self");
  }
  render() {
    return (


      <>
        <MainHeader></MainHeader>
        <div className="row mr-0 ml-0  bg-community-banner error-page-top position-relative">
          <div className="leftbg app-bg1 ">
          </div>
          <div className="midbg ">
            <div className="continer2 text-left pt-4">
              <MiddleHeader heading1="Welcome to InsuranceGIG" heading2="Connecting Buyers and Sellers through Insurance Microservices"></MiddleHeader>
              <div className="p-4 mid-sec float-left">
                <div className="register-msg-font successmsg-a-font text-center" style={{
                  borderWidth: "0px", padding: "0.5rem 0.5rem", fontSize: "20px"
                }} >
                  {
                    this.state.identityProviderError != '' ? <><h5>{this.state.identityProviderError}</h5></>: 
                    this.state.errorMessage != '' ? <>
                    <h5>Sorry, we didn’t recognize that account.</h5>
                    <div>Please click <a onClick={this.onLinkClick} className="registration-b" style={{ display: "inline-block" }}> here</a> to register a new account.</div><br /></>
                      : <><IgigLoadingIcon  ></IgigLoadingIcon><p>Processing Request....</p><br /></>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="rightbg app-bg2">

          </div>
        </div>
        <div className="container-fluid mid3 mt-2">
          <div className="row pb-2 pt-2">
            <div className="col-md-12 col-12 text-left">

            </div>
          </div>
        </div>
        <ListingFooter></ListingFooter>
      </>


    );
  }
}

export default withRouter(ChromeCallback)