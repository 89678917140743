import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Feedback from '../assets/images/send.svg';
import Help from '../assets/images/help.svg';
import Utilities from '../Common/util';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import imgSend from '../assets/images/send.svg';
import ReactPlayer from 'react-player';
import Loader from 'react-loader-spinner';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import { Checkbox } from 'primereact/checkbox';
import CommonValues from '../Common/util';

class RTGServiceHeader extends Component {
    constructor(props) {
        super(props);
        this.token = "";
        this.state = {
            feedbackErrors: {},
            feedbackMsg: '',
            feedbackLoading: false,
            showFeedbackPopup: false,
            VideoAction: false,
            VideoData: [],
            videoLink: "",
            loadingVideoUpdate: false,
            showPopupForHelp: false,
            videoURL: '',
            videoHelpText: '',
            showPopupForConsent: false,
            IsConsentAgreed: false,
            errors: {},
            serviceId: ""
        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;

        this.setState({
            serviceId: params.index
        });

        this.token = Utilities.GetToken();
        if (this.token == "" || this.token == null) {
            Utilities.Logout();
        }
        // alert(this.props.showVideoToUser)
        this.getVideoDetail(params.index);
        this.getServiceDataById(params.index);

        if (this.props.showVideoToUser == true) {
            this.setState({
                showPopupForHelp: true
            });
        }
    }

    getServiceDataById = (serviceId) => {

        var axios = require('axios');
        var data = JSON.stringify({
            "searchText": this.state.searchText,
            "ServiceId": serviceId
        });
        let token = CommonValues.GetToken();
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Local_Url}/api/account/getuserwidgets`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then((response) => {
                this.setState({
                    service: response.data[0],
                    headerLoading: false
                });

                if (this.state.service.isConsentRequired == true) {
                    if (this.state.service.isConsentAgreed == true) {
                        //show all tabs and video

                        this.setState({
                            sequenceNo: 6,
                            showMainTab: true
                        });

                        this.getvideostatus(response.data[0].widgetCode, response.data[0].widgetId, response.data[0].videoCode)
                    }
                    else {
                        //show about,pricing,t&c tabs only
                        this.setState({
                            sequenceNo: 6,
                            showMainTab: false
                        });
                    }
                }
                else {
                    //show all tabs and video   
                    this.setState({
                        sequenceNo: 6,
                        showMainTab: true
                    });
                    // this.getvideostatus(response.data[0].widgetCode, response.data[0].widgetId, response.data[0].videoCode)
                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    headerLoading: false
                });

                if (error.response != null) {

                    if (error.response.status === 401) {
                        CommonValues.Logout();
                    } else {

                    }
                }
                else {

                }
            });
    }
    onChangeFeedback = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onChangeCheckBoxForConsent = (IsConsentAgreed) => {
        this.setState({
            IsConsentAgreed: !IsConsentAgreed
        });

    }

    getVideoDetail = (serviceId) => {
        var axios = require('axios');
        var data = JSON.stringify({
            "VideoCode": this.props.service.videoCode,
            "Widget": this.props.service.widgetCode,
            "WidgetId": this.props.service.widgetId
        });
        let token = CommonValues.GetToken();
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Local_Url}/getwidgetvideoDetail`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then((response) => {
                if (response.data != null) {
                    if (response.data.videoURL != null && response.data.videoURL != '') {
                        this.setState({
                            videoURL: response.data.videoURL,
                            videoHelpText: response.data.helpText
                        }
                            // ,this.getvideostatus()
                        );
                    }
                }
            });
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (this.state.IsConsentAgreed == false) {
            formIsValid = false;
            errors["IsConsentAgreed"] = "Please accept the terms and conditions.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onHideConsentForm = () => {

        this.setState({
            showPopupForConsent: false
        });

    }

    updateConsentStatus = () => {
        if (!this.handleValidation()) {
            return false;
        }
        let token = CommonValues.GetToken();

        var axios = require('axios');
        var data = JSON.stringify({
            "UserId": 0,
            "WidgetId": this.props.service.widgetId,
            "Widget": this.props.service.widgetCode,
            "IsConsentAgreed": this.state.IsConsentAgreed
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Local_Url}/updateconsentstatus`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                if (response.data == true) {
                    this.toast.show({
                        severity: 'success',
                        summary: 'Success message',
                        detail: 'Consent response saved successfully.',
                        life: 6000,
                        position: 'bottom-right'
                    });
                    this.onHideConsentForm();
                    this.getVideoDetail();

                }
            })
            .then((error) => {
                console.log(error);
            });

    }
    // getvideostatus = () => {

    //     var axios = require('axios');
    //     var data = JSON.stringify({
    //         "Agent": "",
    //         "VideoCode": this.props.service.videoCode,
    //         "Widget": this.props.service.widgetCode,
    //         "WidgetId": this.props.service.widgetId
    //     });
    //     var config = {
    //         method: 'post',
    //         url: `${process.env.REACT_APP_Local_Url}/getvideostatus`,
    //         headers: {
    //             'Authorization': `Bearer ${this.token}`,
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };
    //     axios(config)
    //         .then((response) => {
    //             this.setState({
    //                 VideoData: response.data
    //             });
    //             if (this.state.VideoData.length != 0) {
    //                 if (this.state.VideoData[0].doNotShow != true) {
    //                     this.setState({ showPopupForHelp: true });
    //                 }
    //             }

    //             else if (this.state.VideoData.length == 0) {
    //                 this.setState({ showPopupForHelp: true });
    //             };
    //             console.log(this.state.VideoData.length);
    //         })
    //         .then((error) => {
    //             console.log(error);
    //         });
    // }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
        this.setState({
            feedbackErrors: {}
        });
    }
    handleFeedbackValidation() {
        let feedbackErrors = {};
        let formIsValid = true;
        if (this.state.feedbackMsg.trim() == "") {
            formIsValid = false;
            this.state.feedbackMsg = "";
            feedbackErrors["feedbackMsg"] = "Feedback is required.";
        }
        this.setState({ feedbackErrors: feedbackErrors });
        return formIsValid;
    }
    onFeedbackSubmit = () => {
        if (!this.handleFeedbackValidation()) {
            return false;
        }
        var data = JSON.stringify({
            WidgetCode: this.props.service.widgetCode,
            Feedback: this.state.feedbackMsg,
            feedbackbY: Utilities.GetUserEmail(),
        });
        this.setState({
            feedbackLoading: true
        })
        let token = CommonValues.GetToken();
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_Local_Url}/savefeedback`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${token}`
            },
            data: data,
        };
        axios(config)
            .then((response) => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Success message',
                    detail: 'Feedback submitted successfully.',
                    life: 6000,
                    position: 'bottom-right'
                });
                this.setState({
                    feedbackLoading: false,
                    feedbackMsg: "",
                    showFeedbackPopup: false
                });
            }).catch((error) => {
                this.setState({
                    feedbackLoading: false,
                    showFeedbackPopup: false
                });
                if (error.response != null && error.response.status == 401) {
                    Utilities.Logout();
                } else {
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error message',
                        detail: 'Oops... something went wrong. Please try again later.',
                        life: 3000
                    });
                }
            });
    }
    openHelpDialog = () => {
        this.setState({
            showPopupForHelp: true
        });
    }
    onChangeCheckBox = (e) => {
        this.setState({ [e.target.name]: e.target.checked }, function () {
            this.onHideVideoClick();
        });
    };
    onHideVideoClick = () => {
        var axios = require('axios');
        var data = JSON.stringify({
            "Agent": "",
            "VideoCode": this.props.service.videoCode,
            "Widget": this.props.service.widgetCode,
            "DoNotShow": this.state.VideoAction
        });
        this.setState({
            loadingVideoUpdate: true
        });
        let token = CommonValues.GetToken();
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Local_Url}/updatevideostatus`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    showPopupForHelp: false,
                    loadingVideoUpdate: false,
                    VideoData: "0"
                });
                this.toast.show(
                    {
                        severity: 'success',
                        summary: 'Success message ',
                        detail: 'Response saved successfully.',
                        life: 2000
                    });

            })
            .then((error) => {
                console.log(error);
                this.setState({
                    showPopup: false,
                    loadingVideoUpdate: false
                });

            });

    }

    selectCheckbox = () => {
        this.setState({
            IsConsentAgreed: !this.state.IsConsentAgreed
        })
    }

    render() {

        return (
            <>
                <Toast ref={(el) => this.toast = el} />

                <div className="card card-semsee">
                    <div className="card-body">
                        <div className="semsee-v3-main">
                            <div className="d-flex align-items-center">
                                <div className="v3-logo">
                                    <img src={this.props.service.serviceIconURL} alt={this.props.service.serviceName} />
                                </div>
                                <div className="semsee-title">
                                    <span className="text-purple text-24 font-weight-600">{this.props.service.serviceCompanyName}</span>
                                    <span className="text-purple text-16 d-block pt-1">{this.props.service.tagline}</span>
                                </div>
                            </div>
                            <div>

                                <button className="btn primary-btn-transperent" onClick={() => { this.setState({ showFeedbackPopup: true }) }} >
                                    <img src={Feedback} className="svg-primary mr-2"
                                        title='Click here to provide feedback.'
                                    />Send feedback
                                </button>
                                {
                                    (this.state.videoURL != "" && this.state.videoURL != null && this.state.videoURL != undefined) ?
                                        <button className="btn btn-outline-primary help-rtg ml-2" onClick={() => { this.openHelpDialog() }}><img src={Help} className="help-rtg-ico" alt="" title='Click here to view help video.' /></button>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog header="Feedback" visible={this.state.showFeedbackPopup} value={this.state.show} style={{ width: '60vw' }} onHide={() => this.onHide('showFeedbackPopup')} draggable={false} >
                    <div className="form-group mb-0">
                        <textarea className="form-control textAreaHeight" name="feedbackMsg" value={this.state.feedbackMsg} onChange={this.onChangeFeedback} rows={3} placeholder="Provide Feedback..." defaultValue={""} />
                        &nbsp;<span className="text-red">{this.state.feedbackErrors["feedbackMsg"]}</span>
                    </div>
                    <div className="semsee-wizard-btns mr-2">

                        <button id="prevBtn" className="pull-right" title='Click here to provide feedback.'
                            loading={this.state.feedbackLoading}
                            onClick={this.onFeedbackSubmit}>
                            <img src={imgSend} className=" svg-white mr-2" />
                            Send feedback</button>
                    </div>

                </Dialog>
                <Dialog header={this.state.videoHelpText} draggable={false} visible={this.state.showPopupForHelp} value={this.state.show} style={{ width: '60vw' }} onHide={() => this.onHide('showPopupForHelp')} draggabl={false} >
                    <div className="w-100">
                        <ReactPlayer className="react-video" width="100%"
                            height="520px"
                            url={this.state.videoURL}
                            controls={true}
                            playing={true}
                            style={{ margin: "0 auto", height: '100%' }}
                        />
                    </div>
                    <div className='form-check mb-0'>
                        <div className="custom-control custom-checkbox videoAlign">
                            {
                                this.state.loadingVideoUpdate ?
                                    <div className="text-center align-items-center mr-5 address-book-main" >
                                        <div className="row auth-loader mrgntp ">
                                            {/* <Loader type="ThreeDots" color="#603c96" height="30" width="30" />&nbsp; */}
                                            <IgigLoadingIcon ></IgigLoadingIcon>
                                        </div>
                                        <span className='ml-2 pt-1 text-12 text-hash-comment'>Updating your response...</span>
                                    </div>
                                    :
                                    <>
                                        <br />
                                    </>
                            }
                            {
                                this.state.VideoData.length == 0 &&
                                <>
                                    <input type="checkbox" className="custom-control-input" id="VideoAction" name="VideoAction" checked={this.state.VideoAction} onChange={this.onChangeCheckBox} />
                                    <label className="custom-control-label pt-1" htmlFor="VideoAction">Don't show again</label>
                                </>
                            }
                        </div>
                    </div>
                </Dialog>

                {/* <Dialog header="Consent Form" visible={this.state.showPopupForConsent} value={this.state.show} style={{ width: '60vw' }} onHide={() => this.onHide('showPopupForConsent')} draggable={false} >
                    <div className='consent-form'>
                        <span>
                            Broker Buddha Inc.
                            This is done in RTGServiceHeader.js

                        </span>
                    </div>
                    <div className="semsee-wizard-btns mr-2 mt-5 text-center">
                        <input type="checkbox" id="IsConsentAgreed" name="IsConsentAgreed" value={this.state.IsConsentAgreed} className="termconditioninput" onClick={this.selectCheckbox} />

                        <label className="" >I accept the terms and conditions </label>
                        <div>
                            <span className="text-red">{this.state.errors["IsConsentAgreed"]}</span>
                        </div>
                        <div className='mt-3'>
                            <button id="prevBtn" className="" title='Click here to provide feedback.'
                                loading={this.state.feedbackLoading}
                                onClick={this.updateConsentStatus}>
                                <img src={imgSend} className="  svg-white mr-2" />
                                Agree Consent</button>
                        </div>

                    </div>
                </Dialog> */}
            </>
        );
    }
}
export default (withRouter(RTGServiceHeader));