
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LogoutImg from '../../src/assets/images/new-images/logout.svg';
import CommonValues from '../Common/util';
import { Avatar } from 'primereact/avatar';
import IgigLogo from '../../src/assets/images/new-images/insurancegig_logo-white.png';
import Logo from '../../src/assets/images/new-images/Artboard 9@4x-8.png'
import { Dialog } from 'primereact/dialog';
import filterIcon from '../../src/assets/images/new-images/filter.svg';
import MenuHeader from '../../src/assets/images/menu.svg';
import InsLogoPurple from '../../src/assets/images/insurancegig_logo.png';
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import IgigDefaultBtn from '../Components/IgigDefaultBtn';
import Maintextlogo from '../assets/images/new-images/wholetextfinal.png'
import NowcertLogo from '../assets/images/nowcert1.png'
import addUser from '../../src/assets/images/add-user.svg';
import BtisLogo from '../assets/images/Btis-partner1.png'
class AgentHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: 'Dashboard',
            avatarName: "",
            mobileSidebar: false,
            displayBasic: false,
            position: 'center',
            IconURL: ''

        }
    }
    componentDidMount = () => {

    }
    onSignInClick = () => {
        window.location = ("/login");
    }
    onHover = (selectedItem) => {
        this.setState({ selectedItem: selectedItem });
    }
    onLogoutClick = (e) => {
        CommonValues.Logout();
    }
    onClick = (name, position) => {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }
    onHide = (name) => {
        this.setState({
            [`${name}`]: false
        });
    }
    onLoginClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Login page',
            action: 'Click on login button',
        });
        {
            this.props.tenantId == 4 || this.props.indexValue && this.props.indexValue.toLowerCase() == 'nowcerts' ?
                window.location = ("/login/nowcerts")
                :
                this.props.indexValue && this.props.indexValue.toLowerCase() == 'btis' ?
                    window.location = ("/login/btis")
                    :
                    window.location = ("/login");
        }
        e.preventDefault();
    }
    onClickAgentOnboarding = () => {
        let slugURL = CommonValues.GetSlugURL();
        if (slugURL != null && slugURL != undefined) {
            var url = "/agentonboarding/" + slugURL;
            window.open(url, "_blank")
        }
    }
    onMyProfiledClick = (e) => {
        this.props.history.push("/profile")
        e.preventDefault();
    }
    onSignupLinkClicked = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Register page',
            action: 'Click on register button',
        });
        this.props.history.push("/register-seller");
        e.preventDefault();
    }
    render() {
        return (
            <>
                <nav className="main-header-new container-fluid main-header-before-login header-position"  >
                    {this.props.showFilterLogo ?
                        null
                        :
                        (CommonValues.GetHasGIGMarket() == 'false') ?
                            <div className="d-flex align-items-center">
                                {this.props.indexValue && this.props.indexValue.toLowerCase() == 'nowcerts' || this.props.tenantId == 4 || this.props.indexValue && this.props.indexValue.toLowerCase() == 'btis' ?
                                    null
                                    :
                                    <span className="mb-0 logo-before-login desktopViewofLogo">
                                        <a href="/" className="sidebar-logo desktopViewofLogo" >
                                            <span className="logo desktopViewofLogo">
                                                <img src={IgigLogo} className="img-fluid desktopViewofLogo" />
                                            </span>
                                        </a>
                                    </span>
                                }
                                <div className="logo-text logonewheader desktopViewofLogo ml-2">
                                    {this.props.indexValue && this.props.indexValue.toLowerCase() == 'btis' ?
                                        <a className="nowcert-link" >
                                            <img src={BtisLogo} className="textlogo-btis" />
                                        </a>
                                        :
                                        <a href={this.props.indexValue && this.props.indexValue.toLowerCase() == 'nowcerts' || this.props.tenantId == 4 ? null : '/'} className="nowcert-link" >
                                            <img src={this.props.indexValue && this.props.indexValue.toLowerCase() == 'nowcerts' || this.props.tenantId == 4 ? NowcertLogo : Maintextlogo} className="textlogo1" />
                                        </a>
                                    } </div>
                            </div>
                            :
                            (this.state.IconURL == '' || this.state.IconURL == null) ?
                                <div className="d-flex align-items-center">
                                    {(CommonValues.GetTenantId() == process.env.REACT_APP_NowCert_Tenant_Id) ?
                                        null
                                        :
                                        <span className="mb-0 logo-before-login desktopViewofLogo">

                                            <a href="/" className="sidebar-logo desktopViewofLogo">
                                                <span className="logo desktopViewofLogo">
                                                    <img src={IgigLogo} className="img-fluid desktopViewofLogo" />
                                                </span>
                                            </a>

                                        </span>
                                    }
                                    <div className="logo-text logonewheader desktopViewofLogo ml-2">
                                        {this.props.indexValue && this.props.indexValue.toLowerCase() == 'btis' ?
                                            <a className="nowcert-link" >
                                                <img src={BtisLogo} className="textlogo-btis" />
                                            </a>
                                            :
                                            <a href={this.props.indexValue && this.props.indexValue.toLowerCase() == 'nowcerts' || this.props.tenantId == 4 ? null : '/'} className="nowcert-link" >
                                                <img src={CommonValues.GetTenantId() == process.env.REACT_APP_NowCert_Tenant_Id ? NowcertLogo : Maintextlogo} className="textlogo1" />
                                            </a>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="d-flex align-items-center">
                                    <div className="logo-text logonewheader desktopViewofLogo ml-2">
                                        <a href={CommonValues.GetGIGDashboardURL()} > <img src={this.state.IconURL} className="companylogo" /></a>
                                    </div>
                                </div>

                    }
                    {CommonValues.GetToken() !== null ?

                        null
                        :
                        <div className="before-login-desktop-menu">
                            <span className='mr-2  ml-auto' >
                                <IgigDefaultBtn onClick={this.onLoginClick} text="Login"></IgigDefaultBtn>
                            </span>
                        </div>
                    }
                </nav>


                <nav className={CommonValues.GetToken() !== null ? " container-fluid mobileheader" : "  container-fluid mobileheader "}>
                    <div className='row'>
                        <div className='col-3 text-center'>
                            {CommonValues.GetToken() !== null ?
                                null
                                :
                                <>
                                    <ul style={{ listStyle: "none", marginTop: "unset", marginBottom: "unset" }}>
                                        <li className="" >
                                            <a className="nav-link event-page-link p-0" onClick={() => this.onClick('displayBasic')}>
                                                <img src={MenuHeader} className="" />
                                            </a>
                                        </li>
                                    </ul>
                                    <Dialog className='header-mb-sidebar' visible={this.state.displayBasic} style={{ width: '50vw' }} onHide={() => this.onHide('displayBasic')}>
                                        <ul className="list-unstyled components mb-5">

                                            <li className={this.props.pageName == "login" ? " active menu-hiding" : "menu-hiding"} onClick={this.onLoginClick} onMouseOver={() => this.onHover('login')} data-toggle="tooltip" data-placement="bottom" title="Login">
                                                <a href="/login" className="event-page-link" >
                                                    <img src={LogoutImg} className="mr-2" />
                                                    <span>Login</span></a>
                                            </li>
                                            {/* <li className={this.props.pageName == "register-seller" ? " active menu-hiding" : "menu-hiding"} onClick={this.onSignupLinkClicked} onMouseOver={() => this.onHover('register-seller')} data-toggle="tooltip" data-placement="bottom" title="register-seller">
                                                <a href="/register-seller" className="event-page-link" >
                                                    <img src={addUser} className="mr-2" />
                                                    <span>List Your Service</span></a>
                                            </li> */}
                                        </ul>
                                    </Dialog>
                                </>
                            }
                        </div>
                        <div className='col-6 text-center'>
                            <span className='text-18 header-heading-mb'>

                                {this.props.pageName == "agencyonboarding" ? "Agency onboarding" : null}
                                {this.props.pageName == "Useronboarding" ? "User Onboarding" : null}
                            </span>
                        </div>
                        <div className='col-3 text-center'>
                            <a href="/">
                                <img src={InsLogoPurple} className="img-fluid" width='40' />
                            </a>
                        </div>
                    </div>
                </nav>


            </>
        )
    }
}
export default withRouter(AgentHeader);
