import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import IgigLoadingIcon from "../../Components/IgigLoadingIcon";
import IgigErrorMessage from "../../Components/IgigErrorMessage";
import IgigInfoMessage from "../../Components/IgigInfoMessage";
import CommonValues from "../../Common/util";
import axios from "axios";
import IgigSuccessMessage from "../../Components/IgigSuccessMessage";
import IgigInputBox from "../../Components/IgigInputBox";
import { Message } from 'primereact/message';
        

export default class Capital extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      capitalLoading: false,
      deleteCapitalLoading: false,
      userName: "",
      searchCode: "",
      password: "",
      showIGCredsMsg: false,
      errorMessageForCapitalResponse: "",
      successMessageForCapitalResponse: "",
      errorMessageUsername: "",
      errorMessagePassword: "",
      errorMessageSearchCode: "",
    };
  }

  componentDidMount = () => {
    this.getCapitalCreds();
  };

  getCapitalCreds = () => {
    this.setState({
      loading: true,
    });
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 204) {
          this.setState({
            userName: "",
            searchCode: "",
            password: "",
            showIGCredsMsg: true,
          });
        } else {
          this.setState({
            showIGCredsMsg: false,
            userName: response.data.username,
            searchCode: response.data.searchCode,
          });
          if (
            response.data.username !== null &&
            response.data.username !== "" &&
            response.data.password === ""
          ) {
            this.setState({
              password: "*********",
            });
          }
        }
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while loading the Capital Setting details";
          }
        } else {
          errorMessage =
            "Unknown error while loading the Capital Setting details";
        }
        this.setState({
          errorMessageForCapitalResponse: errorMessage,
          loading: false,
        });
      });
  };

  capitalCheckValidation = () => {
    let formIsValid = true;
    this.setState({
      errorMessageUsername: "",
      errorMessagePassword: "",
      errorMessageSearchCode: "",
    });

    if (this.state.userName == "" || this.state.userName == null) {
      formIsValid = false;
      this.setState({
        errorMessageUsername: "Please enter username",
      });
    }
    if (this.state.password == "" || this.state.password == null) {
      formIsValid = false;
      this.setState({
        errorMessagePassword: "Please enter password",
      });
    }

    return formIsValid;
  };

  onUpdateCapitalClick = () => {
    if (this.capitalCheckValidation()) {
      this.setState({
        capitalLoading: true,
      });
      let token = CommonValues.GetToken();
      var axios = require("axios");
      var data = JSON.stringify({
        Username: this.state.userName,
        Password: this.state.password,
        SearchCode: this.state.searchCode,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          this.setState({
            capitalLoading: false,
            errorMessageForCapitalResponse: "",
          });
          setTimeout(() => {
            this.setState({
              successMessageForCapitalResponse: "",
            });
          }, 5000);
          this.setState({
            successMessageForCapitalResponse:
              "Capital Settings updated successfully",
          });
          this.getCapitalCreds();
        })
        .catch((error) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout();
            } else if (error.response.status == 409) {
              errorMessage =
                "It look likes you are not registered with Capital, Please register first.";
            } else {
              errorMessage =
                "Unknown error while updating the Capital Settings";
            }
          } else {
            errorMessage = "Unknown error while updating the Capital Settings";
          }
          setTimeout(
            () => this.setState({ errorMessageForCapitalResponse: "" }),
            5000
          );
          this.setState({
            errorMessageForCapitalResponse: errorMessage,
            capitalLoading: false,
          });
        });
    }
  };

  onDeleteCapitalClick = () => {
    this.setState({
      deleteCapitalLoading: true,
    });
    let token = CommonValues.GetToken();
    var axios = require("axios");

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          deleteCapitalLoading: false,
          errorMessageForCapitalResponse: "",
        });
        setTimeout(
          () => this.setState({ successMessageForCapitalResponse: "" }),
          5000
        );
        this.setState({
          successMessageForCapitalResponse:
            "Capital Settings deleted successfully",
        });
        this.getCapitalCreds();
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          } else {
            errorMessage = "Unknown error while deleting the Capital Settings";
          }
        } else {
          errorMessage = "Unknown error while deleting the Capital Settings";
        }
        setTimeout(
          () => this.setState({ errorMessageForCapitalResponse: "" }),
          5000
        );
        this.setState({
          errorMessageForCapitalResponse: errorMessage,
          deleteCapitalLoading: false,
        });
      });
  };

  onKeyUpdateCapitalClick = (e) => {
    if (e.keyCode == 13) {
      this.onUpdateCapitalClick();
    }
  };

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  onSearchCodeChange = (e) => {
    this.setState({
      searchCode: e.target.value,
    });
  };

  onUsernameChange = (e) => {
    this.setState({
      userName: e.target.value,
    });
  };

  render() {
    return (
      <Accordion activeIndex={1}>
        <AccordionTab header="Capital Premium Financing">
          <div className="row">
            {this.state.loading ? (
              <div className="col-12">
                <IgigLoadingIcon></IgigLoadingIcon>
              </div>
            ) : (
              <>
                <div className="igCredentialMessage">
                  {this.state.showIGCredsMsg ? (
                    <Message severity="warn" text="Using InsuranceGIG Credentials" />
                  ) : null}
                </div>

                <div className="col-12">
                  <div className=" form-group">
                    <label>
                      Username<span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <IgigInputBox
                        errorMessage={this.state.errorMessageUsername}
                        value={this.state.userName}
                        className="form-control"
                        type="text"
                        id="userName"
                        name="userName"
                        onChange={this.onUsernameChange}
                        onKeyDown={this.onKeyUpdateCapitalClick}
                        step="0.01"
                        min="0"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className=" form-group">
                    <label>
                      Password<span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <IgigInputBox
                        errorMessage={this.state.errorMessagePassword}
                        value={this.state.password}
                        className="form-control"
                        type="text"
                        id="headerKey"
                        name="headerKey"
                        onChange={this.onPasswordChange}
                        onKeyDown={this.onKeyUpdateCapitalClick}
                        step="0.01"
                        min="0"
                      ></IgigInputBox>
                      <span className="">{this.errorMessagePassword}</span>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className=" form-group">
                    <label>Search Code</label>
                    <div className="">
                      <IgigInputBox
                        // errorMessage={this.state.errorMessageSearchCode}
                        value={this.state.searchCode}
                        className="form-control"
                        type="text"
                        id="ownerSearchCode"
                        name="ownerSearchCode"
                        onChange={this.onSearchCodeChange}
                        onKeyDown={this.onKeyUpdateCapitalClick}
                        step="0.01"
                        min="0"
                      ></IgigInputBox>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ margin: "10px" }}>
                  <div className="col-6">
                    {this.state.capitalLoading ? (
                      <IgigLoadingIcon></IgigLoadingIcon>
                    ) : (
                      <Button
                        label="Save"
                        icon=""
                        onClick={this.onUpdateCapitalClick}
                        className=" p-button-sm "
                      />
                    )}
                  </div>
                  <div className="col-6">
                    {this.state.deleteCapitalLoading ? (
                      <IgigLoadingIcon></IgigLoadingIcon>
                    ) : (
                      <Button
                        label="Reset"
                        icon=""
                        onClick={this.onDeleteCapitalClick}
                        className=" p-button-sm "
                      />
                    )}
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  {this.state.errorMessageForCapitalResponse != null &&
                  this.state.errorMessageForCapitalResponse != "" ? (
                    <IgigErrorMessage
                      errorMessage={this.state.errorMessageForCapitalResponse}
                    ></IgigErrorMessage>
                  ) : null}
                </div>

                <div className="col-md-12 mb-2">
                  {this.state.successMessageForCapitalResponse != null &&
                  this.state.successMessageForCapitalResponse != "" ? (
                    <IgigSuccessMessage
                      message={this.state.successMessageForCapitalResponse}
                    ></IgigSuccessMessage>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </AccordionTab>
      </Accordion>
    );
  }
}
