import React from "react";
import { Component } from "react";
import IgigLoadingIcon from "../../Components/IgigLoadingIcon";
import CommonValues from "../../Common/util";
import MainHeader from "../../../src/OtherCommonPages/MainHeader";
import ArrowLeft from "../../assets/images/new-images/arrow-left.svg";
import RTGServiceHeader from "../RTGServiceHeader";
import PFFees from "./PFFees";
import Capital from "./CapitalPF";
import GotoPF from "./GotoPF";
import PFcompanies from "./PFCompanies";

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      premiumSettings: [],
      premiumLoading: true,
      errorMessage: "",
      successMessage: "",
      saveSettingLoading: false,
      showSidebar: false,
      service: {},
      serviceName: "",
      serviceId: "",
      sequenceNo: 0,
      showMainTab: false,
      VideoData: [],
      ShowCurrentPage: true,
      headerLoading: true,
      showCard: false,
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;

    this.setState({
      serviceId: params.index,
    });
    this.getServiceDataById(params.index);

    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCert_Tenant_Id) {
      this.setState({
        showFooterImage: false,
      });
    }

    if (
      CommonValues.GetTenantId() == process.env.REACT_APP_NowCert_Tenant_Id ||
      CommonValues.GetTenantId() == process.env.REACT_APP_BTIS_Tenant_Id
    ) {
      this.setState({
        showCompletedForms: true,
      });
    }

    let hasGiGMarket = localStorage.getItem("HasGIGMarket");
    if (hasGiGMarket != null && hasGiGMarket != "" && hasGiGMarket != "true") {
      this.setState({
        ShowCurrentPage: true,
      });
    } else {
      this.setState({
        ShowCurrentPage: false,
      });
    }
  };

  getServiceDataById = (serviceId) => {
    var axios = require("axios");
    var data = JSON.stringify({
      searchText: this.state.searchText,
      ServiceId: serviceId,
    });
    let token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_Local_Url}/api/account/getuserwidgets`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.setState({
          service: response.data[0],
          showVideoToUser: response.data[0].showVideoToUser,
          headerLoading: false,
        });

        if (this.state.service.isConsentRequired == true) {
          if (this.state.service.isConsentAgreed == true) {
            //show all tabs and video
            this.setState({
              showMainTab: true,
            });
            this.setCardVisible();
            this.getvideostatus(
              response.data[0].widgetCode,
              response.data[0].widgetId,
              response.data[0].videoCode
            );
          } else {
            //show about,pricing,t&c tabs only
            this.setState({
              sequenceNo: 6,
              showMainTab: false,
            });
            this.setCardVisible();
          }
        } else {
          //show all tabs and video   getvideostatus = (widgetCode, widgetId, videoCode) => {
          this.setState({
            showMainTab: true,
          });
          this.setCardVisible();
          this.getvideostatus(
            response.data[0].widgetCode,
            response.data[0].widgetId,
            response.data[0].videoCode
          );
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          headerLoading: false,
        });

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout();
          } else {
          }
        } else {
        }
      });
  };

  getvideostatus = (widgetCode, widgetId, videoCode) => {
    let token = CommonValues.GetToken();
    var axios = require("axios");
    var data = JSON.stringify({
      Agent: "",
      VideoCode: videoCode,
      Widget: widgetCode,
      WidgetId: widgetId,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_Local_Url}/getvideostatus`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.setState({
          VideoData: response.data,
        });
        if (this.state.VideoData.length != 0) {
          if (this.state.VideoData[0].doNotShow != true) {
            this.setState({ showPopupForHelp: true });
          }
        } else if (this.state.VideoData.length == 0) {
          this.setState({ showPopupForHelp: true });
        }
      })
      .then((error) => {
        console.log(error);
      });
  };

  setCardVisible = () => {
    this.setState({
      showCard: true,
    });
  };
  onBackBtnClick = () => {
    window.location = "/yourcompanyname/service/145";
}

  render() {
    return (
      <div className="subscibed-service-details-main">
        {this.state.ShowCurrentPage ? (
          <>
            <IgigLoadingIcon></IgigLoadingIcon>
          </>
        ) : (
          <>
            <MainHeader
              showSidebar={this.state.showSidebar}
              pageName="subscribe-service-details"
            ></MainHeader>
            <div className="wrapper-new d-flex align-items-stretch subscribed-height">
              <button
                class="btn btn-primary-transperent back-btn-transperent subscribed-back-btn"
                onClick={this.onBackBtnClick}
              >
                <img src={ArrowLeft} class="img-secondary img-secondary-back" />
              </button>
              <div
                id="content main "
                className="content mid-container detail-content"
              >
                <div className="container-fluid service-details">
                  <div className="service-details subscribed-service-details">
                    {this.state.headerLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "100%", height: "120px" }}
                      >
                        <IgigLoadingIcon></IgigLoadingIcon>
                      </div>
                    ) : (
                      <RTGServiceHeader
                        service={this.state.service}
                        serviceId={this.state.serviceId}
                        showVideoToUser={this.state.showVideoToUser}
                      />
                    )}
                  </div>
                </div>
                {this.state.showCard ? (
                  <div className="card">
                    <div className="card-body  p-3 gig-services">
                      <div className="subscribed-service-details mymarkettab">
                        <div className="row">
                          <div className="col-4">
                            <PFcompanies></PFcompanies>
                          </div>
                          <div className="p-0"></div>
                          <div className="col-4">
                            <Capital></Capital>
                          </div>
                          <div className="col-4">
                            <GotoPF></GotoPF>
                          </div>
                        </div>
                         
                      </div>
                    </div>
                  </div>
                ) : (
                  <IgigLoadingIcon></IgigLoadingIcon>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
