import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LogoutImg from '../../src/assets/images/new-images/logout.svg';
import CommonValues from '../Common/util';
import IgigSecondaryBtn from '../Components/IgigSecondaryBtn';
import IgigDefaultBtn from '../Components/IgigDefaultBtn';
import { Avatar } from 'primereact/avatar';
import IgigLogo from '../../src/assets/images/new-images/insurancegig_logo-white.png';
import Logo from '../assets/images/new-images/wholetextfinal.png'
import { Dialog } from 'primereact/dialog';
import DashboardImg from '../../src/assets/images/new-images/dashboard.svg';
import CompanyImg from '../../src/assets/images/new-images/company-profile.svg';
import AccountUser from '../../src/assets/images/new-images/account-users.svg';
import filterIcon from '../../src/assets/images/new-images/filter.svg';
import Settings from '../../src/assets/images/new-images/settings.svg';
import ReportImg from '../../src/assets/images/new-images/reports.svg';
import CalenderImg from '../../src/assets/images/new-images/calendar.svg';
import MarketplaceImg from '../../src/assets/images/new-images/Layout 3.svg';
import MenuHeader from '../../src/assets/images/menu.svg';
import InsLogoPurple from '../../src/assets/images/insurancegig_logo.png';
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import addUser from '../../src/assets/images/add-user.svg';
import strokeImg from '../../src/assets/images/new-images/reqservice.svg';
import VideoImg from '../../src/assets/images/new-images/youtube (1).svg';
import CommunityImg from '../../src/assets/images/new-images/flag1.svg';
import chromeExtImg from '../assets/images/IG_ChromeExtensionButton.png';
import Maintextlogo from '../assets/images/new-images/wholetextfinal.png'
import NowcertLogo from '../assets/images/nowcert1.png'
import { animateScroll as scroll } from 'react-scroll'

class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: 'Dashboard',
            avatarName: "",
            mobileSidebar: false,
            displayBasic: false,
            position: 'center',
            IconURL: '',
            GIGLogoURL: '/',
            forgotpasswordpath: "",
            setpasswordpath: ""

        }
    }
    componentDidMount = () => {
        scroll.scrollToTop();
        let logo = CommonValues.GetCompanyLogo();

        if ((logo != null && logo != '' && logo != 'null') && CommonValues.GetHasGIGMarket() == 'true') {
            this.setState({
                IconURL: logo,
                GIGLogoURL: CommonValues.GetGIGDashboardURL()
            })
        }
        else if ((logo == null || logo == '') && CommonValues.GetHasGIGMarket() == 'true') {
            this.setState({
                GIGLogoURL: CommonValues.GetGIGDashboardURL()
            })
        }
        let forgotpasswordpath = window.location.href.split('forgotpassword/')[1]
        this.setState({
            forgotpasswordpath: forgotpasswordpath
        })

        let setpasswordpathwithtoken = window.location.href.split('setpassword/')[1]
        let setpasswordpath = '';
        if (setpasswordpathwithtoken != '' && setpasswordpathwithtoken != null && setpasswordpathwithtoken != undefined) {
            setpasswordpath = setpasswordpathwithtoken.split('/')[1]
        }
        this.setState({
            setpasswordpath: setpasswordpath
        })

    }
    onClick = (name, position) => {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }
    onHide = (name) => {
        this.setState({
            [`${name}`]: false
        });
    }
    onLogoutClick = (e) => {
        CommonValues.Logout();
    }
    onMyProfiledClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'User Profile Page',
            action: 'Click on user profile button',
        });
        this.props.history.push("/profile")
        e.preventDefault();
    }
    onAccountUserClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Account Users  Page',
            action: 'Click on account users button',
        });
        this.props.history.push("/users")
        e.preventDefault();
    }
    onMyCompanyClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Company Page',
            action: 'Click on company button',
        });
        this.props.history.push("/company")
        e.preventDefault();
    }
    onHover = (selectedItem) => {
        this.setState({ selectedItem: selectedItem });
    }
    onLoginClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Login page',
            action: 'Click on login button',
        });
        //this.props.history.push("/login")
        if (this.state.forgotpasswordpath && this.state.forgotpasswordpath.toLowerCase() == 'nowcerts' ||
             this.state.setpasswordpath && this.state.setpasswordpath.toLowerCase() == 'nowcerts') {
            window.location.replace('/login/nowcerts');
        } else {
            window.location.replace('/login');
        }

        e.preventDefault();
    }
    onRequestServiceClicked = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Request Service Page',
            action: 'Click on request service button',
        });
        this.props.history.push("/requestservice")
        e.preventDefault();
    }
    onRegisterClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Register page',
            action: 'Click on register button',
        });
       
        {
            this.state.forgotpasswordpath && this.state.forgotpasswordpath.toLowerCase() == 'nowcerts' || this.state.setpasswordpath &&  this.state.setpasswordpath.toLowerCase() == 'nowcerts' ?
                window.location.replace("/agencyonboarding/nowcerts")
                :
                window.location.replace("/agencyonboarding?utm_source=header")
        }
        e.preventDefault();
    }
    onListYourServiceClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Register page',
            action: 'Click on list your service button',
        });
        this.props.history.push("/register-seller")
        e.preventDefault();
    }
    sidebarClicked = () => {
        this.setState({
            mobileSidebar: !this.state.mobileSidebar
        })
    }
    onClickAgentOnboarding = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Agentonboarding  Page',
            action: 'Click on add users button',
        });
        let slugURL = CommonValues.GetSlugURL();
        if (slugURL != null && slugURL != undefined) {
            window.open("/agentonboarding/" + slugURL, "_blank");
        }
    }
    onClickReport = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Report Page',
            action: 'Click on usage report button',
        });
        window.location.href = "/reports";
    }
    onClickCompanyDetail = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Company Page',
            action: 'Click on company details button',
        });
        window.location.href = "/company";
    }
    onClickPaymentDetail = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Payment Details Page',
            action: 'Click on payment details button',
        });
        window.location.href = "/company/payment";
    }
    onClickMyGIGServices = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'My Services Page',
            action: 'Click on my gigmarket button',
        });
        window.location.href = this.state.GIGLogoURL;
    }
    onAddCromeExtensionClick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Header',
            action: 'Click on chrome extension button',
        });
        window.open("https://chrome.google.com/webstore/detail/insurancegig/pidaodnmlepjkmkldnfommgpckflndfg", "_blank") //to open new page
    }

    render() {
        return (
            <>
                <nav className="main-header-new container-fluid main-header-before-login header-position"  >
                    {this.props.showFilterLogo ?
                        null
                        :
                        ((CommonValues.GetHasGIGMarket() == 'true') && (this.props.pageName == "Workflow" || this.props.pageName == "subscribe-service-details" || this.props.pageName == "UserProfile" || this.props.pageName == "CompanyProfile" || this.props.pageName == "Request service" || this.props.pageName == "Reports")) ?
                            (this.state.IconURL == '' || this.state.IconURL == null) ?

                                <div className="d-flex align-items-center">
                                    <span className="mb-0 logo-before-login desktopViewofLogo">
                                        <a href={this.state.GIGLogoURL} className="sidebar-logo desktopViewofLogo">
                                            <span className="logo desktopViewofLogo">
                                                <img src={IgigLogo} className="img-fluid desktopViewofLogo" />
                                            </span>
                                        </a>
                                    </span>
                                    <div className="logo-text logonewheader desktopViewofLogo ml-2">
                                        <a href={this.state.GIGLogoURL}> <img src={Logo} className="textlogo1" /></a>
                                    </div>
                                </div>
                                :
                                <div className="d-flex align-items-center">
                                    <div className="logo-text logonewheader desktopViewofLogo ml-2">
                                        <a href={this.state.GIGLogoURL}> <img src={this.state.IconURL} className="companylogo" /></a>
                                    </div>
                                </div>
                            :
                            <div className="d-flex align-items-center">
                                {this.state.forgotpasswordpath && this.state.forgotpasswordpath.toLowerCase() == 'nowcerts' || this.state.setpasswordpath && this.state.setpasswordpath.toLowerCase() == 'nowcerts' ?
                                    null
                                    :
                                    <span className="mb-0 logo-before-login desktopViewofLogo">
                                        <a href="/" className="sidebar-logo desktopViewofLogo">
                                            <span className="logo desktopViewofLogo">
                                                <img src={IgigLogo} className="img-fluid desktopViewofLogo" />
                                            </span>
                                        </a>
                                    </span>
                                }
                                <div className="logo-text logonewheader desktopViewofLogo ml-2">
                                    <a href="/" style={{ cursor: "pointer" }}>
                                         <img src={this.state.forgotpasswordpath && this.state.forgotpasswordpath.toLowerCase() == 'nowcerts' || this.state.setpasswordpath && this.state.setpasswordpath.toLowerCase() == 'nowcerts' ? NowcertLogo : Maintextlogo} className="textlogo1" /></a>
                               
                                </div>
                            </div>
                    }

                    {CommonValues.GetToken() !== null ?
                        <ul className="navbar-nav ml-auto nav-flex-icons align-items-center" style={{ flexDirection: "unset" }}>
                            {(CommonValues.GetHasGIGMarket() == 'true') ?
                                <li className="nav-item avatar dropdown  mr-2 displayfordesktop">
                                    <button type="submit"
                                        onClick={() => this.onAddCromeExtensionClick()}
                                        className="btn ">
                                        <img src={chromeExtImg} className="rounded" style={{ height: "40px" }} />
                                    </button>
                                </li>
                                :
                                null
                            }
                            <li className="nav-item avatar dropdown d-flex justify-content-end ">
                                {this.props.pageName == "vertafore" ?
                                    null
                                    :

                                    <a href className="nav-link displayfordesktop" id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Avatar label={CommonValues.GetAvtarName().toUpperCase()} className="user-image avatar-new-header" size="large" shape="circle" />
                                    </a>
                                }
                                <div className="dropdown-menu dropdown-menu-right dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-5">
                                    {
                                        (CommonValues.GetHasGIGMarket() == 'true') ?
                                            <a className={this.state.selectedItem == "Logout" ? "dropdown-item  " : "dropdown-item "} onClick={this.onClickMyGIGServices} onMouseOver={() => this.onHover('Logout')}>
                                                My GigMarket</a> : null
                                    }
                                    {CommonValues.GetCompanyName() != "null" ?

                                        <a className={this.state.selectedItem == "Logout" ? "dropdown-item " : "dropdown-item "} onClick={this.onMyProfiledClick} onMouseOver={() => this.onHover('Logout')}>
                                            User Profile</a>
                                        :
                                        <a className={this.state.selectedItem == "Logout" ? "dropdown-item " : "dropdown-item "} onClick={this.onMyCompanyClick} onMouseOver={() => this.onHover('Logout')}>
                                            <i className="fa fa-briefcase mr-3"></i>Company</a>
                                    }
                                    {
                                        (CommonValues.GetHasGIGMarket() == 'true') ?
                                            <a className={this.state.selectedItem == "Logout" ? "dropdown-item " : "dropdown-item "} onClick={this.onClickCompanyDetail} onMouseOver={() => this.onHover('Logout')}>
                                                Company details</a> : null
                                    }
                                    {
                                        ((CommonValues.GetRoleId() == 2) && CommonValues.GetHasGIGMarket() == 'true') ?
                                            <a className={this.state.selectedItem == "Logout" ? "dropdown-item " : "dropdown-item "} onClick={this.onClickPaymentDetail} onMouseOver={() => this.onHover('Logout')}>
                                                Payment Details</a> : null
                                    }

                                    {
                                        CommonValues.GetHasGIGMarket() == 'true' ?
                                            <a className={this.state.selectedItem == "Logout" ? "dropdown-item " : "dropdown-item "} onClick={this.onClickAgentOnboarding} onMouseOver={() => this.onHover('Logout')}>
                                                Add Users</a>
                                            : null
                                    }
                                    {
                                        (CommonValues.GetHasGIGMarket() == 'true') ?
                                            <a className={this.state.selectedItem == "Logout" ? "dropdown-item  " : "dropdown-item "} onClick={this.onClickReport} onMouseOver={() => this.onHover('Logout')}>
                                                Usage Report</a> : null
                                    }
                                    <a className={this.state.selectedItem == "Logout" ? "dropdown-item " : "dropdown-item"} onClick={this.onLogoutClick} onMouseOver={() => this.onHover('Logout')}>
                                        <img src={LogoutImg} className="mr-2 img-primary" />Logout</a>
                                </div>
                            </li>
                        </ul>
                        :
                        <>
                            {this.props.pageName == "vertafore" ?
                                null
                                :

                                <div className=" before-login-desktop-menu  ">
                                    {/* <a onClick={this.onListYourServiceClick} className="mr-2 listbtn">List Your Service</a> */}
                                    <span className='mr-2  ml-auto' >
                                        <IgigDefaultBtn onClick={this.onLoginClick} text="Login"></IgigDefaultBtn>
                                    </span>

                                    {this.props.pageName == "Useronboarding" || this.props.pageName == "agencyonboarding" ?
                                        null
                                        :
                                        <IgigSecondaryBtn onClick={this.onRegisterClick} text="Sign Up"></IgigSecondaryBtn>
                                    }

                                </div>
                            }
                        </>
                    }
                </nav>

                {/* } */}
                <nav className={CommonValues.GetToken() !== null ? " container-fluid mobileheader" : "  container-fluid mobileheader "}>
                    <div className='row'>
                        <div className='col-3 text-center'>


                            <ul style={{ listStyle: "none", marginTop: "unset", marginBottom: "unset" }}>

                                <li className="" >
                                    {this.props.pageName == "vertafore" ?
                                        null
                                        :
                                        <a className="nav-link event-page-link p-0" onClick={() => this.onClick('displayBasic')}>

                                            <img src={MenuHeader} className="" />

                                        </a>
                                    }
                                </li>

                            </ul>
                            <Dialog className='header-mb-sidebar' visible={this.state.displayBasic} style={{ width: '50vw' }} onHide={() => this.onHide('displayBasic')}>
                                <ul className="list-unstyled components mb-5">
                                    <li className={this.state.selectedItem == "requestdemo" ? " active" : ""}
                                        onClick={this.onMarketplaceClick} onMouseOver={() => this.onHover('requestdemo')}
                                        data-toggle="tooltip" data-placement="bottom" title="AppMaRequest Demorket">

                                    </li>
                                    {this.props.pageName == "vertafore" ?
                                        null
                                        :
                                        <>
                                            <li className={this.props.pageName == "login" ? " active menu-hiding" : "menu-hiding"} onClick={this.onLoginClick} onMouseOver={() => this.onHover('login')} data-toggle="tooltip" data-placement="bottom" title="Login">
                                                <a href="/login" className="event-page-link" >
                                                    <img src={LogoutImg} className="mr-2" />
                                                    <span>Login</span></a>
                                            </li>
                                            {this.props.pageName == "Useronboarding" || this.props.pageName == "agencyonboarding" ?
                                                null
                                                :
                                                <li className={this.state.selectedItem == "register" ? " active" : ""} onClick={this.onRegisterClick} onMouseOver={() => this.onHover('Register')} data-toggle="tooltip" data-placement="bottom" title="Register">
                                                    <a href="/agencyonboarding" className="event-page-link" >
                                                        <img src={addUser} className="mr-2" />
                                                        <span>Register</span></a>
                                                </li>
                                            }

                                        </>
                                    }

                                    <li className={this.state.selectedItem == "marketplace" ? " active" : ""} onClick={this.onMarketplaceClick} onMouseOver={() => this.onHover('marketplace')} data-toggle="tooltip" data-placement="bottom" title="AppMarket">
                                        <a href="/" >
                                            <img src={MarketplaceImg} className="mr-2" />
                                            <span>AppMarket</span></a>
                                    </li>
                                    {this.props.pageName == "Useronboarding" || this.props.pageName == "agencyonboarding" ?
                                        null
                                        :
                                        <>
                                            <li className={this.state.selectedItem == "Community" ? " active" : ""} onClick={this.onCommunityClick} onMouseOver={() => this.onHover('Community')} data-toggle="tooltip" data-placement="bottom" title="Community">
                                                <a href="/community/dashboard" >
                                                    <img src={CommunityImg} className="mr-2" />
                                                    <span>Community</span></a>
                                            </li>
                                            <li className={this.state.selectedItem == "Blog" ? " active" : ""} onClick={this.onBlogClick} onMouseOver={() => this.onHover('Blog')} data-toggle="tooltip" data-placement="bottom" title="Blog">
                                                <a href="/blogs" >
                                                    <img src={ReportImg} className="mr-2" />
                                                    <span>Blog</span></a>
                                            </li>
                                            <li className={this.state.selectedItem == "Events" ? " active" : ""} onClick={this.onEventsClick} onMouseOver={() => this.onHover('Events')} data-toggle="tooltip" data-placement="bottom" title="Events">
                                                <a href="/community/events" >
                                                    <img src={CalenderImg} className="mr-2" />
                                                    <span>Events</span></a>
                                            </li>

                                            <li className={this.state.selectedItem == "videos" ? " active" : ""} onClick={this.onVideoClick} onMouseOver={() => this.onHover('videos')} data-toggle="tooltip" data-placement="bottom" title="Videos">
                                                <a href="/videos" >
                                                    <img src={VideoImg} className="mr-2" />
                                                    <span>Videos</span></a>
                                            </li>

                                            {CommonValues.GetToken() == null ?
                                                <li className={this.state.selectedItem == "requestservice" ? " active req-active " : " req-active"}
                                                    onClick={this.onRequestServiceClicked} onMouseOver={() => this.onHover('requestservice')}
                                                    data-toggle="tooltip" data-placement="bottom" title="Request Service"
                                                    style={{ marginTop: "50px", background: "#FFFACC" }}
                                                >
                                                    <a href="/" >
                                                        <img src={strokeImg} className="mr-2" />
                                                        <span>Request Service</span></a>
                                                </li>
                                                :
                                                null
                                            }
                                        </>
                                    }
                                </ul>
                            </Dialog>
                        </div>
                        <div className='col-6 text-center'>
                            <span className='text-18 header-heading-mb'>
                                {this.props.pageName == "login" ? "Login" : null}
                                {this.props.pageName == "forgotpassword" ? "Forgot password" : null}
                                {this.props.pageName == "resetpassword" ? "Reset password" : null}
                                {this.props.pageName == "setpassword" ? "Set password" : null}
                                {this.props.pageName == "register" ? "List Your Service" : null}
                                {this.props.pageName == "dashboard" ? "Community" : null}
                                {this.props.pageName == "Blog" ? "Blog" : null}
                                {this.props.pageName == "events" ? "Events" : null}
                                {this.props.pageName == "videos" ? "Videos " : null}
                                {this.props.pageName == "Request service" ? "Request service" : null}
                                {this.props.pageName == "marketplace" ? "AppMarket" : null}
                                {this.props.pageName == "Service" ? "My Services" : null}
                                {this.props.pageName == "servicedetails" ? "Service details" : null}
                                {this.props.pageName == "Admindashboard" ? "Admin dashboard" : null}
                                {this.props.pageName == "CompanyProfile" ? "Company" : null}
                                {this.props.pageName == "UserProfile" ? "User profile" : null}
                                {this.props.pageName == "accountuser" ? "Account users" : null}
                                {this.props.pageName == "adduser" ? "Account users" : null}
                                {this.props.pageName == "Workflow" ? "Workflows used" : null}
                                {this.props.pageName == "Reports" ? "Report" : null}
                                {this.props.pageName == "workflowdashboard" ? "Active workflows" : null}
                                {this.props.pageName == "workflowdetails" ? "Workflow details" : null}
                                {this.props.pageName == "vertafore" ? "Vertafore" : null}
                            </span>
                        </div>
                        <div className='col-3 text-center'>
                            <a href="/"> <img src={InsLogoPurple} className="img-fluid" width='40' /></a>
                        </div>
                    </div>
                </nav>

                {
                    CommonValues.GetToken() == null
                        ?
                        null
                        :
                        <>
                            <nav className="main-header-new container-fluid mobileview-header">
                                <div className="logo-text">
                                    <img src={MenuHeader} className="" onClick={() => this.onClick('displayBasic')} />

                                    <a href="/"> <img src={Logo} className="textlogo" /></a>
                                    <img src={IgigLogo} className=" user-image mobile-header-logo float-right " />
                                </div>
                            </nav>
                            <Dialog className='header-mb-sidebar' visible={this.state.displayBasic} style={{ width: '50vw' }} onHide={() => this.onHide('displayBasic')}>
                                <ul className="list-unstyled components mb-5">

                                    <li className={this.state.selectedItem == "marketplace" ? " active" : ""} onClick={this.onMarketplaceClick} onMouseOver={() => this.onHover('marketplace')} data-toggle="tooltip" data-placement="bottom" title="AppMarket">
                                        <a href="/" >
                                            <img src={MarketplaceImg} className="mr-2" />
                                            <span>AppMarket</span></a>
                                    </li>
                                    {this.props.pageName == "Useronboarding" || this.props.pageName == "agencyonboarding" ?
                                        null
                                        :
                                        <>
                                            <li className={this.state.selectedItem == "Community" ? " active" : ""} onClick={this.onCommunityClick} onMouseOver={() => this.onHover('Community')} data-toggle="tooltip" data-placement="bottom" title="Community">
                                                <a href="/community/dashboard" >
                                                    <img src={CommunityImg} className="mr-2" />
                                                    <span>Community</span></a>
                                            </li>
                                            <li className={this.state.selectedItem == "Blog" ? " active" : ""} onClick={this.onBlogClick} onMouseOver={() => this.onHover('Blog')} data-toggle="tooltip" data-placement="bottom" title="Blog">
                                                <a href="/blogs" >
                                                    <img src={ReportImg} className="mr-2" />
                                                    <span>Blog</span></a>
                                            </li>
                                            <li className={this.state.selectedItem == "Events" ? " active" : ""} onClick={this.onEventsClick} onMouseOver={() => this.onHover('Events')} data-toggle="tooltip" data-placement="bottom" title="Events">
                                                <a href="/community/events" >
                                                    <img src={CalenderImg} className="mr-2" />
                                                    <span>Events</span></a>
                                            </li>
                                            <li className={this.state.selectedItem == "Videos" ? " active" : ""} onClick={this.onVideoClick} onMouseOver={() => this.onHover('videos')} data-toggle="tooltip" data-placement="bottom" title="Videos">
                                                <a href="/videos" >
                                                    <img src={VideoImg} className="mr-2" />
                                                    <span>Videos</span></a>
                                            </li>
                                        </>
                                    }
                                    {
                                        CommonValues.GetToken() != null
                                            ?
                                            <>
                                                {
                                                    CommonValues.GetCompanyName() !== "null" ?
                                                        <>
                                                            {CommonValues.GetHasGIGMarket() == 'true' ?
                                                                null
                                                                :
                                                                <li className={this.state.selectedItem == "Dashboard" ? "active sidebarautorizeitem" : "sidebarautorizeitem"} onClick={this.onDashboardClick} onMouseOver={() => this.onHover('Dashboard')} >
                                                                    <a href="/dashboard" >
                                                                        <img src={DashboardImg} className="mr-2" />
                                                                        <span>Dashboard</span></a>
                                                                </li>
                                                            }
                                                        </>
                                                        :
                                                        null}

                                                <li className={this.state.selectedItem == "Company" ? " active sidebarautorizeitem" : "sidebarautorizeitem"} onClick={this.onCompanyClick} onMouseOver={() => this.onHover('Company')}>
                                                    <a href="/company" >
                                                        <img src={CompanyImg} className="mr-2" />
                                                        <span>Company</span></a>
                                                </li>

                                                {
                                                    CommonValues.GetCompanyName() !== "null" ?
                                                        <>
                                                            <li className={this.state.selectedItem == "UserProfile" ? " active sidebarautorizeitem" : "sidebarautorizeitem"} onClick={this.onMyProfiledClick} onMouseOver={() => this.onHover('UserProfile')}>
                                                                <a href="/profile" >
                                                                    <img src={filterIcon} className="mr-2" />
                                                                    <span>User Profile</span></a>
                                                            </li>

                                                            {CommonValues.GetHasGIGMarket() == 'true' ?
                                                                null
                                                                :
                                                                <li className={this.state.selectedItem == "accountuser" ? " active sidebarautorizeitem" : "sidebarautorizeitem"} onClick={this.onAccountUserClick} onMouseOver={() => this.onHover('accountuser')}>
                                                                    <a href="/users" >
                                                                        <img src={AccountUser} className="mr-2" />
                                                                        <span>Account Users</span></a>
                                                                </li>
                                                            }
                                                            {CommonValues.GetHasGIGMarket() == 'true' ?
                                                                null
                                                                :
                                                                <li className={this.state.selectedItem == "Service" ? "active sidebarautorizeitem" : "sidebarautorizeitem"} onClick={this.onMyServiceClick} onMouseOver={() => this.onHover('Service')}>
                                                                    <a href="/myservices">
                                                                        <img src={Settings} className="mr-2" />
                                                                        <span>Services</span></a>
                                                                </li>
                                                            }

                                                            <li className={this.state.selectedItem == "Reports" ? " active sidebarautorizeitem" : "sidebarautorizeitem"} onClick={this.onReportsClick} onMouseOver={() => this.onHover('Reports')} >
                                                                <a href="/reports" >
                                                                    <img src={ReportImg} className="mr-2" />
                                                                    <span> Usage Report</span></a>
                                                            </li>

                                                        </>
                                                        :
                                                        null
                                                }


                                            </>
                                            :
                                            null
                                    }

                                    {CommonValues.GetHasGIGMarket() == 'true' ?


                                        <li className={this.state.selectedItem == "requestservice" ? " active req-active " : " req-active"}
                                            onClick={this.onRequestServiceClicked} onMouseOver={() => this.onHover('requestservice')}
                                            data-toggle="tooltip" data-placement="bottom" title="Request Service"
                                            style={{ marginTop: "50px", background: "#FFFACC" }}
                                        >
                                            <a href="/" >
                                                <img src={strokeImg} className="mr-2" />
                                                <span>Request Service</span></a>
                                        </li>
                                        :
                                        null
                                    }



                                    {this.props.pageName == "vertafore" ?
                                        null
                                        :
                                        <>
                                            {
                                                CommonValues.GetCompanyName() !== "null" ?
                                                    <li className={this.state.selectedItem == "Reports" ? " active mt-4 logoutli" : "mt-4 logoutli"} onClick={this.onLogoutClick} onMouseOver={() => this.onHover('Reports')} style={{ position: "relative" }}>
                                                        <a onClick={this.onLogoutClick} >
                                                            <Avatar label={CommonValues.GetAvtarName().toUpperCase()} className="user-image avatar-new-header mr-2" size="small" shape="circle" />
                                                            <span>Logout</span>
                                                            <img src={LogoutImg} className="float-right img-primary logoutimg" />
                                                        </a>
                                                    </li>
                                                    :
                                                    null
                                            }
                                        </>
                                    }
                                </ul>
                            </Dialog>
                        </>
                }
            </>
        )
    }
}
export default withRouter(MainHeader);
