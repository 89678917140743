import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import RtgImg from '../assets/images/new-images/ready-to-go.svg'
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import Apiimg from '../assets/images/new-images/Apiimg.png'
import Bundleimg from '../assets/images/new-images/Bundleimg.png'
import Gigimg from '../assets/images/new-images/Gigimg.png'
import Amsimg from '../assets/images/new-images/Amsimg.png'
class ListingCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            featureId: []
        }
    }
    onServiceCardClick = (workflow) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Landing page',
            action: 'Click on service with id ' + ' ' + workflow,
        });

        if (this.props.routeDemo == true) {
            window.open(`/service/${workflow}`, '_blank');
        }
        else {
            this.props.history.push(`/service/${workflow}`);
        }

    }

    componentDidMount = () => {
        if(this.props.model.featureId != null && this.props.model.featureId !=""){
            let myArray = this.props.model.featureId.split(',')
            this.setState({
                featureId: myArray
            })
        }
      
    }

    render() {

        return (
            <>
                <div className="col-lg-3 col-md-6 desktopcard " onClick={() => this.onServiceCardClick(this.props.model.apiId)}>
                    <div className={this.props.model.liveStatus == "Live" ? "gig-services cardhover liveStatusCard service-card-height" : "gig-services cardhover service-card-height"}>
                        <div className="row service-title d-flex align-items-center pb-2 ">
                            <div className="col-8 d-flex align-items-center">
                                <div className="icon ">
                                    <img src={this.props.model.logoUrl}
                                        alt={this.props.model.name}
                                        className="icon-img" /></div>
                                <span className="text-12 ml-2">{this.props.model.companyName}</span>

                            </div>
                            <div className="col-4 text-right">

                                {this.props.model.liveStatus == "Live" ?
                                    <span className="status-tag-live status-tag" >Live</span>
                                    :
                                    null
                                }
                                {this.props.model.liveStatus == "Coming Soon" ?
                                    <span className="status-tag-pending status-tag" >Pending</span>
                                    :
                                    null
                                }
                                {/* {this.props.model.liveStatus == "RTG" ?
                                    <img src={RtgImg} className="status-tag-rtg"></img>
                                    :
                                    null
                                } */}
                            </div>
                        </div>
                        <span className="text-16 font-weight-bold">{this.props.model.name}</span>
                        {this.props.model.tagline != "" && this.props.model.tagline != null ?
                            <p className="text-12 pt-2"> {this.props.model.tagline.length > 100 ? this.props.model.tagline.substring(0, 100) + '...' : this.props.model.tagline}</p>
                            :
                            null}
                        <div className=" type-img">
                       
                            {
                                this.state.featureId.map((featureid) => {
                                    return (
                                            <img src={featureid == 1 ? Gigimg : featureid == 2 ? Apiimg : featureid == 3 ? Bundleimg :  featureid == 4 ? Amsimg  :null}
                                             alt="" className="mr-2"
                                              title={featureid == 1 ? "Available on GigMarket" : featureid == 2 ? "APIs Available for Integrations" : featureid == 3 ? "Bundled Service" :  featureid == 4 ? "Integrated with AMS": null }/>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>


                <div className="col-lg-3 col-md-6 mobilecard " onClick={() => this.onServiceCardClick(this.props.model.apiId)}>
                    <div className={this.props.model.liveStatus == "Live" ? "gig-services cardhover liveStatusCard " : "gig-services cardhover"}>
                        <div className="service-title d-flex align-items-center pb-2 ">
                            <div className=" col-8 d-flex align-items-center mb-view-logo">
                                <div className="icon "><img src={this.props.model.logoUrl} alt={this.props.model.name} className="icon-img" /></div>
                                <span className="text-12 ml-2">{this.props.model.companyName}</span>

                            </div>
                            <div className=" col-4 text-right">
                                {this.props.model.liveStatus == "Live" ?
                                    <span className="status-tag-live status-tag" >Live</span>
                                    :
                                    null
                                }
                                {this.props.model.liveStatus == "Coming Soon" ?
                                    <span className="status-tag-pending status-tag" >Pending</span>
                                    :
                                    null
                                }
                                {/* {this.props.model.liveStatus == "RTG" ?
                                    <img src={RtgImg} className="status-tag-rtg"></img>
                                    :
                                    null
                                } */}
                            </div>
                        </div>
                        <span className="text-16 font-weight-bold">{this.props.model.name}</span>
                        <div className="index-blog-service">
                            <span className="text-12"><span className="font-weight-600">Service type :</span> {this.props.model.type === "Single" ? "API" : "Bundled"}</span>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}

export default hoistStatics((withRouter(ListingCard)), ListingCard);
