import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import IgigLoadingIcon from "../../Components/IgigLoadingIcon";
import CommonValues from "../../Common/util";
import { Checkbox } from "primereact/checkbox";
import IgigErrorMessage from "../../Components/IgigErrorMessage";
import IgigSuccessMessage from "../../Components/IgigSuccessMessage";

export default class PFcompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      premiumSettings: [],
      premiumLoading: true,
      errorMessage: "",
      successMessage: "",
      saveSettingLoading: false,
    };
  }

  componentDidMount = () => {
    this.getPremiumFinanceSettings();
    localStorage.setItem("currentPage", "PREMIUMFINANCESETTING");
  };

  getPremiumFinanceSettings = () => {
    this.setState({
      premiumLoading: true,
    });
    var token = CommonValues.GetToken();
    var axios = require("axios");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/premiumsetting`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          errorMessage: "",
          premiumSettings: response.data,
          premiumLoading: false,
        });
        this.getPremiumFinanceSettingsById(response.data);
      })
      .catch((error) => {
        let errormessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          } else {
            errormessage =
              "Unknown error while loading the Premium finance Setting details";
          }
        } else {
          errormessage =
            "Unknown error while loading the Premium finance Setting details";
        }
        this.setState({
          premiumLoading: false,
          errorMessage: errormessage,
        });
      });
  };

  getPremiumFinanceSettingsById = (premiumSettings) => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      premiumLoading: true,
    });
    var token = CommonValues.GetToken();
    var axios = require("axios");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        let technology = [];
        premiumSettings.forEach((item) => {
          if (response.data.some((res) => res.pfServiceId === item.id)) {
            technology.push({ id: item.id, name: item.name, isChecked: true });
          } else {
            technology.push({ id: item.id, name: item.name, isChecked: false });
          }
        });
        this.setState({
          premiumSettings: technology,
          premiumLoading: false,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while loading the Premium finance Setting details by id";
          }
        }
        this.setState({
          premiumLoading: false,
          errorMessage: errorMessage,
        });
      });
  };

  onCheckboxChange = (e, i) => {
    let premiumSettingsCurrent = [];
    for (let k = 0; k < this.state.premiumSettings.length; k++) {
      if (k == i) {
        this.state.premiumSettings[k].isChecked =
          !this.state.premiumSettings[k].isChecked;
      }
      premiumSettingsCurrent.push(this.state.premiumSettings[k]);
    }
    this.setState({
      premiumSettings: premiumSettingsCurrent,
    });
  };

  onSaveSettings = (premiumSettings) => {
    let premiumSettingsCurrent = [];
    for (let k = 0; k < premiumSettings.length; k++) {
      if (premiumSettings[k].isChecked == true) {
        premiumSettingsCurrent.push(premiumSettings[k]);
      }
    }
    this.setState({
      saveSettingLoading: true,
    });
    var token = CommonValues.GetToken();
    var axios = require("axios");
    var data = JSON.stringify(premiumSettingsCurrent);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertpfsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        let successMessage = "";
        successMessage = "Settings Saved";
        this.setState({
          successMessage: successMessage,
          errorMessage: "",
          saveSettingLoading: false,
        });

        setTimeout(() => {
          this.getPremiumFinanceSettingsById(premiumSettings);
        }, 600);
        this.getCommonSettings();
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            console.log(error);
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while inserting the Premium finance Setting details";
          }
        } else {
          errorMessage =
            "Unknown error while inserting the Premium finance Setting details";
        }
        this.setState({
          saveSettingLoading: false,
          errorMessage: errorMessage,
        });
      });
  };

  getCommonSettings = () => {
    this.setState({
      errorMessage: "",
    });
    var token = CommonValues.GetToken();
    var axios = require("axios");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        localStorage.setItem("clientCount", response.data.clientCount);
        if (response.data.agencyId != null && response.data.agencyId != "") {
          localStorage.setItem("HawksoftAgencyId", "true");
        } else {
          localStorage.setItem("HawksoftAgencyId", "false");
        }
        if (response.data.pfList == 0) {
          localStorage.setItem("PFSettings", "true");
        } else {
          localStorage.setItem("PFSettings", "false");
        }
        if (
          CommonValues.GetTenantId() ==
          process.env.REACT_APP_Vertafore_Tenant_Id
        ) {
          this.getVertaforeSettings();
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
        this.setState({
          errorMessage: errorMessage,
        });
      });
  };

  getVertaforeSettings = () => {
    var axios = require("axios");

    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (
          response.data.agencyName == "" ||
          response.data.agencyName == null ||
          response.data.userName == "" ||
          response.data.userName == null
        ) {
          localStorage.setItem("VertaforeSetting", "true"); // setVertaforeSettingMessageShow(true);
        } else {
          localStorage.setItem("VertaforeSetting", "false"); //setVertaforeSettingMessageShow(false);
        }
        this.setState({ errorMessage: "" });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout();
          } else {
            errorMessage = "Unknown error while loading the vertafore settings";
          }
        }
        this.setState({ errorMessage: errorMessage });
      });
  };
  onBackBtnClick = () => {
    window.location = CommonValues.GetGIGDashboardURL();
  };

  render() {
    return (
      <Accordion activeIndex={1}>
        <AccordionTab header="Premium Finance Companies">
          <div className="row ">
            <>
              {this.state.premiumLoading ? (
                <div className="col-12">
                  <IgigLoadingIcon></IgigLoadingIcon>
                </div>
              ) : (
                <div className="col-12 ">
                  {this.state.premiumSettings.map((i, index) => {
                    return (
                      <div className="row" key={index + 1}>
                        <div
                          className="col-2 flex-wrap"
                          style={{ padding: "5px 0 0 35px" }}
                        >
                          <Checkbox
                            type="checkbox"
                            label={i.name}
                            value={i.name}
                            name={i.name}
                            checked={i.isChecked}
                            onChange={(e) => this.onCheckboxChange(e, index)}
                          />
                        </div>
                        <span>{i.name}</span>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* <div className="col-8  text-right"></div> */}

              <div className="col-12  text-right">
                {this.state.saveSettingLoading ? (
                  <div className="">
                    <IgigLoadingIcon></IgigLoadingIcon>{" "}
                  </div>
                ) : (
                  <Button
                    label="Save"
                    onClick={() =>
                      this.onSaveSettings(this.state.premiumSettings)
                    }
                    className="Long-btn-color"
                  />
                )}
              </div>
              <div className="col-12  mt-2 ">
                {this.state.errorMessage != "" &&
                this.state.errorMessage != null ? (
                  <IgigErrorMessage
                    errorMessage={this.state.errorMessage}
                  ></IgigErrorMessage>
                ) : null}
                {this.state.successMessage != "" &&
                this.state.successMessage != null ? (
                  <IgigSuccessMessage
                    message={this.state.successMessage}
                  ></IgigSuccessMessage>
                ) : null}
              </div>
            </>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }
}
