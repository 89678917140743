import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import CommonValues from "../../Common/util";
import axios from "axios";
import IgigLoadingIcon from "../../Components/IgigLoadingIcon";
import IgigErrorMessage from "../../Components/IgigErrorMessage";
import IgigSuccessMessage from "../../Components/IgigSuccessMessage";
import IgigInfoMessage from "../../Components/IgigInfoMessage";
import IgigInputBox from "../../Components/IgigInputBox";
import { Message } from "primereact/message";
import IgigDefaultBtn from "../../Components/IgigDefaultBtn";

export default class GotoPF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      input1Loading: false,
      deleteloading: false,
      password: "",
      userName: "",
      userType: "",
      portFolioCode: "",
      showIGCredsMsg: true,
      errorMessageForInput1Response: "",
      successMessageForInput1Response: "",
      errorMessagePassword: "",
      errorMessageUsername: "",
      errorMessageUserType: "",
      errMessagePortfolioCode: "",
      loginLoading: false,
      passwordForLogin: "",
      usernameForLogin: "",
      loginUrl: "",
    };
  }

  componentDidMount = () => {
    this.getInput1Crds();
  };

  getInput1Crds = () => {
    this.setState({
      loading: true,
    });
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 204) {
          this.setState({
            password: "*********",
            userName: "*********",
            userType: "",
            portFolioCode: "",
            showIGCredsMsg: true,
          });
        } else {
          this.setState({
            loading: false,
            password: response.data.password,
            userName: response.data.username,
            userType: response.data.userType,
            portFolioCode: response.data.portfolioCode,
            showIGCredsMsg: false,
          });
          if (
            response.data.username !== null &&
            response.data.username !== "" &&
            response.data.userType !== null &&
            response.data.userType !== "" &&
            response.data.portfolioCode !== null &&
            response.data.portfolioCode !== "" &&
            response.data.accountType !== null &&
            response.data.accountType !== "" &&
            response.data.password === ""
          ) {
            this.setState({ password: "*********" });
          }
        }
        this.setState({
          loading: false,
        });
      })

      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while loading the gotoPremiumFinance Setting details";
          }
        } else {
          errorMessage =
            "Unknown error while loading the gotoPremiumFinance Setting details";
        }
        this.setState({
          errorMessageForInput1Response: errorMessage,
          loading: false,
        });
      });
  };

  input1CheckValidation = () => {
    let formIsValid = true;
    this.setState({
      errorMessagePassword: "",
      errorMessageUsername: "",
      errorMessageUserType: "",
      errMessagePortfolioCode: "",
    });

    if (this.state.password == "" || this.state.password == null) {
      formIsValid = false;
      this.setState({
        errorMessagePassword: "Please enter Password",
      });
    }

    if (this.state.userName == "" || this.state.userName == null) {
      formIsValid = false;
      this.setState({
        errorMessageUsername: "Please enter User Name",
      });
    }
    return formIsValid;
  };

  getGotopfDetailsForLogin = () => {
    this.setState({
      loginLoading: true,
    });
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/gotopfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          loginLoading: false,
        });
        window.open(`${response.data}`, "_blank");
      })

      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while loading the gotoPremiumFinance Setting details";
          }
        } else {
          errorMessage =
            "Unknown error while loading the gotoPremiumFinance Setting details";
        }
        this.setState({
          errorMessageForInput1Response: errorMessage,
          loginLoading: false,
        });
      });
  };

  onUpdateInput1Click = () => {
    if (this.input1CheckValidation()) {
      this.setState({ input1Loading: true });
      let token = CommonValues.GetToken();
      var axios = require("axios");
      var data = JSON.stringify({
        Password: this.state.password,
        Username: this.state.userName,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          this.setState({
            input1Loading: false,
            errorMessageForInput1Response: "",
          });
          setTimeout(
            () => this.setState({ successMessageForInput1Response: "" }),
            5000
          );
          this.setState({
            successMessageForInput1Response:
              "gotoPremiumFinance Settings updated successfully",
          });
          this.getInput1Crds();
        })
        .catch((error) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout();
            } else if (error.response.status == 409) {
              errorMessage =
                "It look likes you are not registered with gotoPremiumFinance, Please register first.";
            } else {
              errorMessage =
                "Unknown error while updating the gotoPremiumFinance Settings";
            }
          } else {
            errorMessage =
              "Unknown error while updating the gotoPremiumFinance Settings";
          }
          setTimeout(
            () => this.setState({ errorMessageForInput1Response: "" }),
            5000
          );
          this.setState({
            errorMessageForInput1Response: errorMessage,
            input1Loading: false,
          });
        });
    }
  };

  onDeleteInput1Click = () => {
    this.setState({ deleteloading: true });
    let token = CommonValues.GetToken();
    var axios = require("axios");
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          errorMessageForInput1Response: "",
        });
        setTimeout(
          () => this.setState({ successMessageForInput1Response: "" }),
          5000
        );
        this.setState({
          successMessageForInput1Response:
            "gotoPremiumFinance Settings deleted successfully",
          password: "",
          userName: "",
          deleteloading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while deleting the gotoPremiumFinance Settings";
          }
        } else {
          errorMessage =
            "Unknown error while deleting the gotoPremiumFinance Settings";
        }
        setTimeout(
          () => this.setState({ errorMessageForInput1Response: "" }),
          5000
        );
        this.setState({
          errorMessageForInput1Response: errorMessage,
          deleteloading: false,
        });
      });
  };

  onRegisterClick = () => {
    window.open(
      "https://www.gotopremiumfinance.com/Portals/0/Documents/gotoPremiumFinance-Broker-Application.pdf?ver=2020-10-21-132653-357",
      "_blank"
    );
  };

  onLoginClick = () => {
    this.getGotopfDetailsForLogin();
    // window.open(
    //   `${loginUrl}&username=${usernameForLogin}&password=${passwordForLogin}`,
    //   "_blank"
    // );
  };

  onKeyUpdateInput1Click = (e) => {
    if (e.keyCode == 13) {
      this.onUpdateInput1Click();
    }
  };

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  onUsernameChange = (e) => {
    this.setState({
      userName: e.target.value,
    });
  };

  render() {
    return (
      <Accordion activeIndex={1}>
        <AccordionTab header="goto Premium Finance">
          <div className="row">
            {this.state.loading ? (
              <div className="col-12">
                <IgigLoadingIcon></IgigLoadingIcon>
              </div>
            ) : (
              <>
                {" "}
                <div className="col-12 ">
                  {this.state.showIGCredsMsg ? (
                    <Message
                      severity="warn"
                      text="Using InsuranceGIG Credentials"
                    />
                  ) : null}
                </div>
                <div className="col-12 form-group">
                  <label>
                    User Name<span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <IgigInputBox
                      errorMessage={this.state.errorMessageUsername}
                      value={this.state.userName}
                      className="form-control"
                      type="text"
                      id="username"
                      name="username"
                      onChange={this.onUsernameChange}
                      onKeyDown={this.onKeyUpdateInput1Click}
                      step="0.01"
                      min="0"
                    ></IgigInputBox>
                  </div>
                </div>
                <div className="col-12 form-group">
                  <label>
                    Password<span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <IgigInputBox
                      errorMessage={this.state.errorMessagePassword}
                      value={this.state.password}
                      className="form-control"
                      type="text"
                      id="password"
                      name="password"
                      onChange={this.onPasswordChange}
                      onKeyDown={this.onKeyUpdateInput1Click}
                      step="0.01"
                      min="0"
                    ></IgigInputBox>
                  </div>
                </div>
                <div className="col-3">
                  {this.state.deleteloading ? (
                    <IgigLoadingIcon></IgigLoadingIcon>
                  ) : (
                    <Button
                      label="Reset"
                      onClick={this.onDeleteInput1Click}
                      icon=""
                      className=" "
                    />
                  )}
                </div>
                <div className="col-3">
                  {this.state.input1Loading ? (
                    <IgigLoadingIcon></IgigLoadingIcon>
                  ) : (
                    <Button
                      label="Save"
                      icon=""
                      onClick={this.onUpdateInput1Click}
                      className=" "
                    />
                  )}
                </div>
                <div className="col-3">
                  {this.state.input1Loading ? (
                    <IgigLoadingIcon></IgigLoadingIcon>
                  ) : (
                    <Button
                      label="Register"
                      icon=""
                      onClick={this.onRegisterClick}
                      className="  "
                    />
                  )}
                </div>
                <div className="col-3">
                  {this.state.loginLoading ? (
                    <IgigLoadingIcon></IgigLoadingIcon>
                  ) : (

                    <Button
                      label="Login"
                      onClick={this.onLoginClick}
                      icon=""
                      className=" "
                    />
                  )}
                </div>
                <div className="col-md-12 mb-2">
                  {this.state.errorMessageForInput1Response != null &&
                  this.state.errorMessageForInput1Response != "" ? (
                    <IgigErrorMessage
                      message={this.state.errorMessageForInput1Response}
                    ></IgigErrorMessage>
                  ) : null}
                </div>
                <div className="col-md-12 mb-2">
                  {this.state.successMessageForInput1Response != null &&
                  this.state.successMessageForInput1Response != "" ? (
                    <IgigSuccessMessage
                      message={this.state.successMessageForInput1Response}
                    ></IgigSuccessMessage>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </AccordionTab>
      </Accordion>
    );
  }
}
