import React, { Component } from 'react'

export default class MiddleHeader extends Component {
    render() {
        return (
            // <div className="text-center main-middle-header-div mb-2" >
            <div className="text-center mb-2 middle-header-main-div" >
                <b className="middleheader-heading" >{this.props.heading1}</b>
                <br />
                <span className="middleheader-para ">{this.props.heading2}</span><br/>
                <span className="middleheader-para ">{this.props.heading3}</span>
            </div>
        )
    }
}
