import React from "react";
import { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import IgigLoadingIcon from "../../Components/IgigLoadingIcon";
import CommonValues from "../../Common/util";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import IgigErrorMessage from "../../Components/IgigErrorMessage";
import IgigSuccessMessage from "../../Components/IgigSuccessMessage";
import IgigInputBox from "../../Components/IgigInputBox";

export default class PFFees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      premiumSettings: [],
      brokerFeeLoading: true,
      saveBrokerFeeLoading: false,
      errorMessage: "",
      successMessage: "",
      brokerFee: "",
    };
  }

  componentDidMount = () => {
    localStorage.setItem("currentPage", "PREMIUMFINANCESETTING");
    this.getPremiumFinanceFeesById();
  };

  getPremiumFinanceFeesById = () => {
    this.setState({
      brokerFeeLoading: true,
      errorMessage: "",
    });
    var token = CommonValues.GetToken();
    var axios = require("axios");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfbrokerfees`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          brokerFee: response.data,
          brokerFeeLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while loading the Premium finance broker fees";
          }
        }
        this.setState({
          setLoadingBrokerFee: false,
          errorMessage: errorMessage,
        });
      });
  };

  onBrokerFeeChange = (e) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (
      /^[0-9]*(\.[0-9]*)?$/.test(e.target.value) &&
      regex.test(e.target.value)
    ) {
      this.setState({
        brokerFee: e.target.value,
      });
    }
  };

  onSaveSettings = (premiumSettings) => {
    let premiumSettingsCurrent = [];
    for (let k = 0; k < premiumSettings.length; k++) {
      if (premiumSettings[k].isChecked == true) {
        premiumSettingsCurrent.push(premiumSettings[k]);
      }
    }

    this.setState({
      saveBrokerFeeLoading: true,
    });
    var token = CommonValues.GetToken();
    var axios = require("axios");
    var config = {
      method: "post",
      url: `${
        process.env.REACT_APP_UserService_Url
      }/api/user/insertpffees?brokerFee=${
        this.state.brokerFee == null ? "" : this.state.brokerFee
      }`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        let successMessage = "";
        successMessage = "Broker Fee saved successfully";
        this.setState({
          successMessage: successMessage,
          errorMessage: "",
          saveBrokerFeeLoading: false,
        });
        setTimeout(() => {
          this.getPremiumFinanceFeesById();
        }, 500);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          } else {
            errorMessage =
              "Unknown error while inserting the Premium finance Setting details";
          }
        } else {
          errorMessage =
            "Unknown error while inserting the Premium finance Setting details";
        }
        this.setState({
          saveBrokerFeeLoading: false,
          errorMessage: errorMessage,
        });
      });
  };

  render() {
    return (
      <Accordion activeIndex={1}>
        <AccordionTab header="Fees">
          <div className=" form-group">
            <label>Broker Fees</label>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">$</span>
              <InputText
                placeholder=""
                value="0"
                className=""
                type="text"
                id="brokerFee"
                name="brokerFee"
                onChange={this.onBrokerFeeChange}
                step="0.01"
                min="0"
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-12"
              style={{ textAlign: "right", paddingTop: "10px" }}
            >
              {this.state.saveBrokerFeeLoading ? (
                <div className="">
                  <IgigLoadingIcon></IgigLoadingIcon>{" "}
                </div>
              ) : (
                <Button
                  label="Save"
                  // onClick={() =>
                  //   this.onSaveSettings(this.state.premiumSettings)
                  // }
                  className="Long-btn-color"
                />
              )}
            </div>
            <div className="col-12" style={{ paddingTop: "10px" }}>
              {this.state.errorMessage != "" &&
              this.state.errorMessage != null ? (
                <IgigErrorMessage
                  errorMessage={this.state.errorMessage}
                ></IgigErrorMessage>
              ) : null}
              {this.state.successMessage != "" &&
              this.state.successMessage != null ? (
                <IgigSuccessMessage
                  message={this.state.successMessage}
                ></IgigSuccessMessage>
              ) : null}
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }
}
