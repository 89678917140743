import React, { Component } from 'react'
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
export default class FaqPage extends Component {
    template1(options) {
        const toggleIcon = options.collapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up';
        const className = `${options.className} p-jc-start`;

        return (
            <div className={className} style={{ padding: "10px" }}>
                <span style={{ display: "inline-block" }}>
                    <b>What is insurance premium financing (PremFi)?</b>
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    template2(options) {
        const toggleIcon = options.collapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up';
        const className = `${options.className} p-jc-start`;

        return (
            <div className={className} style={{ padding: "10px" }}>
                <span style={{ display: "inline-block" }}>
                    <b>What is InsuranceGIG?</b>
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }



    template4(options) {
        const toggleIcon = options.collapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up';
        const className = `${options.className} p-jc-start`;

        return (
            <div className={className} style={{ padding: "10px" }}>
                <span style={{ display: "inline-block" }}>
                    <b>
                        Is PremFi, powered by InsuranceGIG available to me and my agency?
                    </b>                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }


    template6(options) {
        const toggleIcon = options.collapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up';
        const className = `${options.className} p-jc-start`;

        return (
            <div className={className} style={{ padding: "10px" }} >
                <span style={{ display: "inline-block" }}>
                    <b>How much does it cost?</b>            </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    onGetDemoClick = () => {
        window.open("https://share.hsforms.com/1QZkNDeTcTY2uXfd5J7l2jgcs5yo", "_blank")
    }

   
    render() {
        return (
            <>
                <div className="col-12 faq-title z-index-1 font-weight-bold text-left">
                    <Panel headerTemplate={this.template1} toggleable collapsed>
                        <p>
                            Insurance Premium Financing is a service that allows individuals and
                            businesses to pay their insurance premium through a third-party
                            lender. Rather than paying the full premium amount upfront, the
                            amount is financed with a special loan and repaid with interest over
                            time.{" "}
                        </p>

                    </Panel><br />
                    <Panel headerTemplate={this.template2} toggleable collapsed>
                        <p>
                            InsuranceGIG is an InsurTech company providing
                            small-to-medium-sized insurance agencies access
                            to modern technology tools. Our goal is to help
                            agencies become more efficient, streamline processes,
                            and provide better service to clients.
                            With our tools and services (we call ‘em Apps),
                            agencies can leverage technology to improve workflows,
                            automate processes, and reduce costs. We bring innovative
                            insurance technology to smaller agencies at a fraction of the cost.
                        </p>
                    </Panel><br />


                    <Panel headerTemplate={this.template4} toggleable collapsed>
                        <p>
                            Currently, PremFi, powered by InsuranceGIG, is exclusively available
                            to Hawksoft users, with plans to expand into other AMS providers in
                            the near future. If you’re a Hawksoft user, get started with a demo.
                            If you’re not a Hawksoft user, join our  <a onClick={() => this.onGetDemoClick()} style={{color:"#007bff"}}>waiting list</a> to be notified
                            when your AMS is integrated with InsuranceGIG.
                        </p>
                    </Panel><br />

                 

                    <Panel headerTemplate={this.template6} toggleable collapsed>
                        <p>
                        It’s totally free of charge for agents to use.
                        </p>
                    </Panel><br />

                 
                </div>
            </>
        )
    }
}
