import React, { Component } from 'react'
import Workflow from '../../src/assets/images/new-images/start-workflow.svg';
import Paper from '../../src/assets/images/new-images/paper.svg';
import RtgImg from '../assets/images/new-images/ready-to-go.svg';

export default class ServicePreviewCommonTopBar extends Component {
  render() {
    return (
        <div className="card detailtopbar">
                <div className="card-body p-3 details-bg gig-services">
                    <div className="service-title d-flex align-items-center pb-2">
                        <div className=" d-flex align-items-center">
                            <div className="icon ">  <a href={this.props.service.website} onClick={(e) => this.onLogoClicked(e, this.props.service.website)}><img src={this.props.service.logoUrl} alt={this.props.service.name} className="icon-img" /></a></div>
                            <span className="text-12 ml-2 mr-2">{this.props.service.ownerCompany}</span>
                            {this.props.service.liveStatus == "Live" ?
                                    <span className="status-tag-live status-tag" >Live</span>
                                    :
                                    null
                                }
                                {this.props.service.liveStatus == "Coming Soon" ?
                                    <span className="status-tag-pending status-tag" >Pending</span>
                                    :
                                    null
                                }
                                {/* {this.props.service.liveStatus == "RTG" ?
                                    <img src={RtgImg} className="status-tag-rtg"></img>
                                    :
                                    null
                                } */}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-6">
                            <h4 className="font-weight-600 service-detailes-title">{this.props.service.name}</h4>
                            <p className="tag-para-height">{this.props.service.tagline}  </p>
                        </div>
                        <div className="col-md-6" />
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-4 service-type-mb">
                          
                            <span className="detail-service-type font-weight-600 ">Service Type : </span> 
                            <span>{this.props.service.type === "Single" ? "API" : "Bundled"}</span>
                           
                        </div>
                        <div className="col-md-8 service-detail-btns">
                            <button className="btn btn-primary workflow-btn mb-1"  service={this.props.service} ><img src={Workflow} className="mr-1 img-white" />Request Workflow</button>
                            {
                                this.props.service.openAPISpecificationURL != null && this.props.service.openAPISpecificationURL != "" ?
                                    <button className="btn btn-outline-primary supp-outline-primary-btn api-details-btn  mb-1"><img src={Paper} class="mr-1 img-primary" />API details</button>
                                    : null
                            }
                            {
                                this.props.service.openApiDocuments != null && this.props.service.openApiDocuments != "" ?
                                    <>
                                        <button className="btn btn-primary workflow-btn  mb-1" >API Docs</button>
                                    </>
                                    : null
                            }

                        </div>
                    </div>
                </div>
            </div>
    )
  }
}
