import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import MiddleHeader from '../Common/MiddleHeader';
import CommonValues from '../Common/util';
import MainHeader from '../OtherCommonPages/MainHeader';
export default class ErrorPage extends Component {
    render() {
        return (
           
            <div className="error-page-main">
             <MainHeader></MainHeader>
                <div className="row mr-0 ml-0  bg-community-banner error-page-top position-relative">
                    <div className="leftbg app-bg1 ">
                        </div>
                    <div className="midbg ">
                        <div className="continer2 text-left pt-4">
                            <MiddleHeader heading1="Welcome to InsuranceGIG" heading2="Connecting Buyers and Sellers through Insurance Microservices"></MiddleHeader>
                            <div className="p-4 mid-sec float-left">
                                <div className="mid-sec-inner pt-3">
                                    <div className="col-md-8 float-left mid1-left text-center">
                                        </div>
                                </div>
                                <div className="mid-sec-inner errorpagetext">
                                   <h3>Oops!  Page not found</h3>
                                   {CommonValues.GetHasGIGMarket() =="false" ?
                                   <h5><a href="/"><u>Goto AppMarket</u></a></h5>
                                   : <h5><a href={CommonValues.GetGIGDashboardURL()}><u>Goto AppMarket</u></a></h5>
                                   }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="rightbg app-bg2">
                       
                    </div>
                </div>
                <div className="container-fluid  mt-2" >
                    <div className="row pb-2 pt-2">
                        <div className="col-md-12 col-12 text-left">

                        </div>
                    </div>
                </div>
                <ListingFooter></ListingFooter>
            </div>
        )
    }
}
