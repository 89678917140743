import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';

export default class IgigCalender extends Component {
    render() {
        return (
            <Calendar
                id="basic"
                className={this.props.errorMessage != null && this.props.errorMessage.length > 0 ? "inputValidation form-control minput" : "form-control minput"}
                value={this.props.value}
                onChange ={this.props.onChange}
            />
        );
    }
}
