import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import ListingCard from './ListingCard';
import ListingFooter from '../Common/ListingFooter';
import Loader from 'react-loader-spinner';
import { ScrollTop } from 'primereact/scrolltop';
import IgigInfoMessage from '../Components/IgigInfoMessage';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import HeaderBackground from '../OtherCommonPages/HeaderBackground'
import MainHeader from '../OtherCommonPages/MainHeader'
import MiddleHeader from '../OtherCommonPages/MiddleHeader'
import Sidebar from '../OtherCommonPages/Sidebar'
import RightArrowSearch from '../../src/assets/images/new-images/arrow-right-search.svg'
import FilterImg from '../../src/assets/images/new-images/filter.svg'
import CloserImg from '../../src/assets/images/close.svg'
import { MultiSelect } from 'primereact/multiselect';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import { InputSwitch } from 'primereact/inputswitch';
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import Apiimg from '../assets/images/new-images/Apiimg.png'
import Bundleimg from '../assets/images/new-images/Bundleimg.png'
import Gigimg from '../assets/images/new-images/Gigimg.png'
import Amsimg from '../assets/images/new-images/Amsimg.png'
import CommonValues from '../Common/util';


var axios = require('axios');

class ListingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            loading: false,

            valueChain: "",
            lineOfBusiness: "",
            typeOfCompany: "",
            serviceType: "",

            searchText: "",
            showMoreLoading: false,
            pageNumber: 1,
            showMoreButton: true,
            showSidebar: false,
            totalResults: 0,
            showSearchBar: false,
            showSearchBarForMobile: false,
            showFilterLogo: false,
            showHideFilterSearch: false,
            lineOfBusinessLoading: false,

            lineOfBusinessIds: [],
            companyTypeIds: [],
            valueChainIds: [],
            serviceTypeNames: [],
            serviceStatusNames: [],
            categoryIds: [],

            hasTryIt: false,
            hasPublished: false,
            filterCount: 0,
            rtgReady: false,
            serviceStatus: "",
            serviceFeature: "",
            serviceFeatureNames: [],
            category: "",
            mainpagestartloading: false
        }

        this.serviceFeatureTypeList = [
            { name: "GigMarket", iconurl: Gigimg },
            { name: "API", iconurl: Apiimg },
            { name: "Bundled", iconurl: Bundleimg },
            { name: "AMS", iconurl: Amsimg }
        ];
        this.serviceStatusList = [
            { name: "Pending", value: "2" },
            { name: "Live", value: "1" },
            { name: "Inactive", value: "4" }
        ];

    }
    componentDidMount() {
        this.getServicesWithAll(this.state.searchText, this.state.pageNumber, true, this.state.companyTypeIds, this.state.lineOfBusinessIds, this.state.valueChainIds, this.state.serviceFeatureNames, this.state.hasTryIt, this.state.hasPublished, this.state.categoryIds, this.state.serviceStatusNames, this.state.rtgReady);
        this.getAllLineOfBusiness();
        this.getAllCompanyType();
        this.getAllValueChain();
        this.getAllCompanySubType();

    }

    onRequestServiceClicked = (e) => {
        this.props.history.push('/requestservice');
        e.preventDefault();
    }

    showSearchBar = () => {
        localStorage.setItem('SidebarValue', this.state.sidebarCollapseBtn)
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Landing page',
            action: 'Click on search filter',
        });
        localStorage.setItem('DemoSidebar', false)
        this.setState({
            showSearchBarForMobile: true,
        })
        this.setState({
            showSearchBar: !this.state.showSearchBar,
            showFilterLogo: !this.state.showFilterLogo,
            showSidebar: false
        })
    }
    closeNav = () => {
        this.setState({
            showSearchBar: false,
            showSidebar: false,
            showFilterLogo: false

        })
    }

    getServicesWithAll = (searchText, pageNumber, replace, companyTypeIds, lineOfBusinessIds, valueChainIds, serviceFeatureNames, hasTryIt, hasPublished, categoryIds, serviceStatusNames, rtgReady) => {

        if (pageNumber > 1) {
            this.setState({
                showMoreLoading: true
            });
        }
        else {
            this.setState({
                loading: true,
            })
        }
        const tagsToSearch = [];
        tagsToSearch.push(companyTypeIds);
        tagsToSearch.push(lineOfBusinessIds);
        tagsToSearch.push(valueChainIds);
        tagsToSearch.push(categoryIds)

        let filterCount = 0;
        if (searchText != "") {
            filterCount++;
        }

        if (companyTypeIds.length > 0) {
            filterCount++;
        }

        if (lineOfBusinessIds.length > 0) {
            filterCount++;
        }

        if (valueChainIds.length > 0) {
            filterCount++;
        }

        if (serviceFeatureNames != "") {
            filterCount++;
        }
        if (categoryIds.length > 0) {
            filterCount++;
        }

        if (serviceStatusNames != "") {
            filterCount++;
        }

        if (hasTryIt) {
            filterCount++;
        }

        if (hasPublished) {
            filterCount++;
        }

        if (rtgReady) {
            filterCount++;
        }
        this.setState({
            searchText: searchText,
            pageNumber: pageNumber,
            companyTypeIds: companyTypeIds,
            lineOfBusinessIds: lineOfBusinessIds,
            valueChainIds: valueChainIds,
            categoryIds: categoryIds,
            serviceFeatureNames: serviceFeatureNames,
            hasTryIt: hasTryIt,
            hasPublished: hasPublished,
            rtgReady: rtgReady,
            serviceStatus: serviceStatusNames,
            filterCount: filterCount
        });

        var data = JSON.stringify({
            "SearchText": searchText,
            "PageNumber": pageNumber,
            "Count": 48,
            "TagsFilter": tagsToSearch,
            "ServiceType": serviceFeatureNames,
            "HasTryIt": hasTryIt,
            "HasPublished": hasPublished,
            "rtgReady": rtgReady,
            "serviceStatus": serviceStatusNames.toString()
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/marketplace/search?`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(config)
            .then((response) => {

                this.setState({
                    totalResults: response.data,
                    showSearchBarForMobile: false
                });
                if (replace == true) {
                    this.setState({
                        services: response.data
                    });
                }
                else {
                    let services = [...this.state.services, ...response.data];
                    this.setState({
                        services: services
                    });
                }

                if (response.data.length == 0) {
                    this.setState({
                        showMoreButton: false
                    });
                } else {
                    this.setState({
                        showMoreButton: true
                    });
                }
                this.setState({
                    loading: false,
                    showMoreLoading: false,
                    // mainpagestartloading: false
                });
            })

            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    showMoreLoading: false,
                });
            });
    }


    onShowmoreClicked = () => {
        if (this.state.showMoreButton) {
            const pageNumber = this.state.pageNumber + 1;
            this.getServicesWithAll(this.state.searchText, pageNumber, false, this.state.companyTypeIds, this.state.lineOfBusinessIds, this.state.valueChainIds, this.state.serviceFeatureNames, this.state.hasTryIt, this.state.hasPublished, this.state.categoryIds, this.state.serviceStatusNames, this.state.rtgReady);
        }
    }

    searchService = (val) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Landing page',
            action: 'Search' + ' ' + val + ' ' + 'service from landing page',
        });
        this.getServicesWithAll(val, 1, true, this.state.companyTypeIds, this.state.lineOfBusinessIds, this.state.valueChainIds, this.state.serviceFeatureNames, this.state.hasTryIt, this.state.hasPublished, this.state.categoryIds, this.state.serviceStatusNames, this.state.rtgReady);
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }
    onSearchKeyClick = (e) => {
        if (e.keyCode == 13) {
            this.searchService(this.state.searchText);
        }
    }
    onSearchIconClick = (e) => {

        this.searchService(this.state.searchText);
    }
    onShowHideSidebar = () => {
        this.setState({
            showSidebar: !this.state.showSidebar,
        })

    }
    onLineOfBusinessChange = (e) => {
        this.setState({ lineOfBusiness: e.value });
        this.setState({
            lineOfBusinessIds: e.value.map(element => element.id)
        })
    }
    onCompanyTypeChange = (e) => {
        this.setState({ typeOfCompany: e.value });
        this.setState({
            companyTypeIds: e.value.map(element => element.id)
        });

    }
    onValueChainChange = (e) => {
        this.setState({ valueChain: e.value })
        this.setState({
            valueChainIds: e.value.map(element => element.id)
        });
    }

    onServiceStatusChange = (e) => {

        this.setState({
            serviceStatus: e.target.value
        });
        this.setState({
            serviceStatusNames: e.value.map(element => element)
        });

    }
    onServiceFeatureChange = (e) => {
        this.setState({
            serviceFeature: e.target.value
        });
        this.setState({
            serviceFeatureNames: e.value.map(element => element.name)

        });


    }
    onCategoryChange = (e) => {
        this.setState({
            category: e.target.value
        });
        this.setState({
            categoryIds: e.value.map(element => element.id)
        });
    }

    getAllCompanyType = () => {
        this.setState({
            companyTypeLoading: true
        })

        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getcompanytype`,

        };
        axios(config)
            .then((response) => {
                this.setState({
                    companyTypeList: response.data,
                    companyTypeLoading: false
                });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyTypeLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {

                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllCompanySubType = () => {
        this.setState({
            companySubTypeLoading: true
        })

        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getallcompanytypesubtype`,

        };
        axios(config)
            .then((response) => {
                this.setState({
                    companySubTypeList: response.data,
                    companySubTypeLoading: false
                });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companySubTypeLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {

                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllValueChain = () => {
        this.setState({
            valueChainLoading: true
        })

        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getallvaluechain`,

        };
        axios(config)
            .then((response) => {
                this.setState({
                    valueChainList: response.data,
                    valueChainLoading: false
                });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    valueChainLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {

                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllLineOfBusiness = () => {
        this.setState({
            lineOfBusinessLoading: true
        });

        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getlineofbusiness`,

        };
        axios(config)
            .then((response) => {
                this.setState({
                    lineOfBusinessList: response.data,
                    lineOfBusinessLoading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    lineOfBusinessLoading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {

                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    clearFilter = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Landing page',
            action: 'Reset all filters',
        });
        this.setState({
            lineOfBusiness: "",
            serviceType: "",
            typeOfCompany: "",
            valueChain: "",
            category: "",
            serviceStatus: "",
            serviceFeature: "",
            serviceStatusNames: [],
            serviceFeatureNames: []

        });
        this.getServicesWithAll("", 1, true, [], [], [], [], false, false, [], [], false);

    }

    searchFromPopver = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Landing page',
            action: 'Search service using filters',
        });
        this.getServicesWithAll(this.state.searchText, 1, true, this.state.companyTypeIds, this.state.lineOfBusinessIds, this.state.valueChainIds, this.state.serviceFeatureNames, this.state.hasTryIt, this.state.hasPublished, this.state.categoryIds, this.state.serviceStatusNames, this.state.rtgReady);
    }
    ChangeShowSidebar = (val) => {
        this.setState({
            showSidebar: val,
            showSearchBar: false
        });
    }

    onaboutClick = () => {
        this.props.history.push("/about")
    }
    countryOptionTemplate(option) {
        return (
            <div className="country-item">
                <img alt={option.name} src={option.iconurl} />  &emsp;{option.name === "GigMarket" ? "GIG Market" : option.name}
            </div>
        );
    }

    render() {

        return (
          
               
                <div className='main-listing-page'>

                    {this.state.mainpagestartloading ?
                        <div className='fs-loader'>
                            <Loader type="ThreeDots" color="#fff" height="30" width="30" style={{ textAlign: "center", margin: this.props.margin, zIndex: 1 }} />
                            <h5 className='z-index-1'>Loading Appmarket....</h5>
                        </div>
                        :
                        <>

                            <MainHeader
                                showSidebar={this.state.showSidebar}
                                pageName="marketplace"
                            ></MainHeader>
                            <div className="wrapper-new d-flex align-items-stretch">
                                <ScrollTop className="scroll-top" />
                                <Sidebar
                                    showSidebar={this.ChangeShowSidebar}
                                    showFilterLogo={this.state.showFilterLogo}
                                    showSearchBar={this.state.showSearchBar}
                                    pageName="marketplace"
                                ></Sidebar>

                                <div className={this.state.showSearchBar ? "bs-canvas  position-fixed bg-light h-100 ml-6 serach-filter-for-desktop pb-4" : "bs-canvas bs-canvas-left position-fixed bg-light h-100"} >
                                    <h3 class="modal-title font-weight-bold pl-2 mt-3" id="myModalLabel" style={{ display: "inline-block" }}>Search Filters</h3>
                                    <button type="button" className="close btn-bg-close mb-serachfilter  close-btn-desktop mt-3" onClick={this.showSearchBar} ><img src={CloserImg} class="img-danger" /></button>
                                    <div className="position-relative p-3 search-filter-group">
                                        <div className="form-group">
                                            {
                                                this.state.lineOfBusinessLoading ?
                                                    <IgigLoadingIcon></IgigLoadingIcon> :
                                                    <>
                                                        <label>Line of Business</label>
                                                        <MultiSelect value={this.state.lineOfBusiness}
                                                            options={this.state.lineOfBusinessList}
                                                            onChange={this.onLineOfBusinessChange}
                                                            optionLabel="name"
                                                            placeholder="Select"
                                                            maxSelectedLabels={5}
                                                            style={{ width: "100%" }} />
                                                    </>
                                            }
                                        </div>


                                        <div className="form-group">
                                            {
                                                this.state.valueChainLoading ?
                                                    <IgigLoadingIcon></IgigLoadingIcon> :
                                                    <>
                                                        <label>Insurance Value Chain</label>
                                                        <MultiSelect value={this.state.valueChain}
                                                            options={this.state.valueChainList}
                                                            onChange={this.onValueChainChange}
                                                            optionLabel="name"
                                                            placeholder="Select"
                                                            maxSelectedLabels={5}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </>
                                            }
                                        </div>


                                        <div className="form-group">
                                            <label>Service Type</label>
                                            <MultiSelect value={this.state.serviceFeature}
                                                options={this.serviceFeatureTypeList}
                                                onChange={this.onServiceFeatureChange}
                                                optionLabel="name"
                                                placeholder="Select"
                                                itemTemplate={this.countryOptionTemplate}
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Categories</label>
                                            <MultiSelect value={this.state.category}
                                                options={this.state.companySubTypeList}
                                                onChange={this.onCategoryChange}
                                                optionLabel="name"
                                                placeholder="Select"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Service Status</label>
                                            <MultiSelect value={this.state.serviceStatus}
                                                options={this.serviceStatusList}
                                                onChange={this.onServiceStatusChange}
                                                optionLabel="name"
                                                placeholder="Select"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className=" form-control-box p-3" style={{ background: "#fff" }}>
                                                <div>
                                                    <label className="mb-0">Has 'Try It' functionality? </label>
                                                </div>
                                                <div className="custom-control custom-switch">
                                                    <InputSwitch className="filterswitch" checked={this.state.hasTryIt} onChange={(e) => this.setState({ hasTryIt: e.value })} onClick={() => this.setState({ hasTryIt: !this.state.hasTryIt })} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className=" form-control-box p-3" style={{ background: "#fff" }}>
                                                <div>
                                                    <label className="mb-0">API Ready</label>
                                                </div>
                                                <div className="custom-control custom-switch">
                                                    <InputSwitch className="filterswitch" onClick={() => this.setState({ hasPublished: !this.state.hasPublished })} checked={this.state.hasPublished} onChange={(e) => this.setState({ hasPublished: e.value })} />

                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-6'>
                                                <button className="btn btn-outline-danger w-100 filter-btns" onClick={this.clearFilter}>Reset filters</button>

                                            </div>
                                            <div className='col-6'>
                                                {this.state.loading ?
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#603c96"
                                                        height={30}
                                                        width={30}
                                                    />
                                                    :
                                                    <button className="btn btn-purple  w-100 filter-btns" onClick={this.searchFromPopver}>Search</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(this.state.showSidebar
                                )
                                    ? "content-new content-width" : "content-new"} id="content-new">
                                    <MiddleHeader></MiddleHeader>
                                    <div className="mid-container-new ">
                                        <div className="row height d-flex justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <div className="search-bar"> <i className="fa fa-search" />
                                                    <input type="text"
                                                        className=" search-focus w-100"
                                                        name="searchText"
                                                        value={this.state.searchText}
                                                        onChange={this.onChange}
                                                        onKeyDown={this.onSearchKeyClick}
                                                        placeholder="Search by service or company name..."
                                                        id="example-search-input"
                                                    />

                                                    <button
                                                        className={(this.state.showSearchBar || this.state.filterCount > 0) ?
                                                            "btn btn-filter-success" : "btn btn-filter"} onClick={this.showSearchBar} >

                                                        <img src={FilterImg} className={!(this.state.showSearchBar || this.state.filterCount > 0) ? "" : "filter-image img-white"} /></button>
                                                    {
                                                        this.state.filterCount == 0 ?
                                                            null :
                                                            <span class="badge badge-pill badge-transparent-success search-filter-items">{this.state.filterCount}</span>
                                                    }

                                                    <a className="arrow-search">
                                                        <img src={RightArrowSearch} onClick={this.onSearchIconClick} className='img-primary' /></a>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <HeaderBackground></HeaderBackground>
                                    {
                                        this.state.loading ?
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "450px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Loader type="ThreeDots" color="#603c96" height="30" width="30" />
                                            </div>
                                            :
                                            <div className="mid-container gigservice-start" >

                                                <div className="row sub-heading2">
                                                    {
                                                        this.state.services.map((model, index) => {
                                                            return (
                                                                index == 8 ?
                                                                    <>
                                                                        <div className="col-md-12 mb-2">
                                                                            <div className="gig-banner ">
                                                                                <div className="row">
                                                                                    <div className="col-md-10 text-white z-index-1 main-banner-height">
                                                                                        <div className=" z-index-1 padding-left-30 ">
                                                                                            <h2 className="mb-3">Do you have access to the most popular insurtech apps?</h2>
                                                                                            <span className="mb-3 mr-3 text-primary-500 text-16 d-block">No integration needed, pay per transaction! You can instantly create your own AppMarket with apps that enable you to work more efficiently, service your clients better and become more successful.  Try it out for free with $25 credit!</span>
                                                                                            <a onClick={this.onaboutClick} ><button className="btn btn-yellow">Create GigMarket</button></a>
                                                                                            {CommonValues.GetToken() !== null ?
                                                                                                null :
                                                                                                <a href="/requestdemo" className='ml-2  '><button className="btn btn-yellow-request-demo">Request a Demo</button></a>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <ListingCard
                                                                            key={index}
                                                                            model={model}
                                                                            deleteBookmarkedService={this.deleteBookmarkedService}
                                                                            selectBookmarkSerivce={this.selectBookmarkSerivce}
                                                                        />

                                                                    </>
                                                                    :
                                                                    <ListingCard
                                                                        key={index}
                                                                        model={model}
                                                                        deleteBookmarkedService={this.deleteBookmarkedService}
                                                                        selectBookmarkSerivce={this.selectBookmarkSerivce}
                                                                    />
                                                            )
                                                        })
                                                    }
                                                </div>

                                                <div style={{ textAlign: "center" }}>
                                                    {
                                                        this.state.showMoreLoading ?
                                                            <Loader
                                                                type="ThreeDots"
                                                                color="#603c96"
                                                                height={30}
                                                                width={30}
                                                            />
                                                            :
                                                            null
                                                    }
                                                </div>
                                                {this.state.services.length == 0 ? (
                                                    <>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ height: "600px" }}>

                                                            <IgigInfoMessage message="The search has not given any results." />
                                                            <div className="new-request  p-md-3">To request a service <a onClick={this.onRequestServiceClicked}>click here.</a></div>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                    }
                                    {this.state.showSearchBar ?
                                        <>
                                            {this.state.showSearchBarForMobile ?
                                                <div>
                                                    <div className="  modal1 left" id="myModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="align-items-center d-flex justify-content-between pl-3 pr-3 mb-2">
                                                                    <h3 className="modal-title font-weight-bold" id="myModalLabel">Search Filters</h3>

                                                                    <button type="button" className="close btn-bg-close mb-serachfilter" onClick={this.showSearchBar} ><img src={CloserImg} class="img-danger" /></button>
                                                                </div>
                                                                <div className="modal-body">

                                                                    <div className="form-group">
                                                                        {
                                                                            this.state.lineOfBusinessLoading ?
                                                                                <IgigLoadingIcon></IgigLoadingIcon> :
                                                                                <>
                                                                                    <label>Line of Business</label>
                                                                                    <MultiSelect value={this.state.lineOfBusiness}
                                                                                        options={this.state.lineOfBusinessList}
                                                                                        onChange={this.onLineOfBusinessChange}
                                                                                        optionLabel="name"
                                                                                        placeholder="Select"
                                                                                        maxSelectedLabels={5}
                                                                                        style={{ width: "100%" }} />
                                                                                </>
                                                                        }
                                                                    </div>

                                                                    <div className="form-group">
                                                                        {
                                                                            this.state.valueChainLoading ?
                                                                                <IgigLoadingIcon></IgigLoadingIcon> :
                                                                                <>
                                                                                    <label>Insurance Value Chain</label>
                                                                                    <MultiSelect value={this.state.valueChain}
                                                                                        options={this.state.valueChainList}
                                                                                        onChange={this.onValueChainChange}
                                                                                        optionLabel="name"
                                                                                        placeholder="Select"
                                                                                        maxSelectedLabels={5}
                                                                                        style={{ width: "100%" }}
                                                                                    />
                                                                                </>
                                                                        }
                                                                    </div>
                                                                    <div className="form-group">

                                                                        <label>Category</label>
                                                                        <MultiSelect value={this.state.category}
                                                                            options={this.state.companySubTypeList}
                                                                            onChange={this.onCategoryChange}
                                                                            optionLabel="name"
                                                                            placeholder="Select"
                                                                            maxSelectedLabels={5}
                                                                            style={{ width: "100%" }}
                                                                        />


                                                                    </div>

                                                                    <div className="form-group">

                                                                        <label>Service Status</label>

                                                                        <MultiSelect value={this.state.serviceStatus}
                                                                            options={this.serviceStatusList}
                                                                            onChange={this.onServiceStatusChange}
                                                                            optionLabel="name"
                                                                            placeholder="Select"
                                                                            maxSelectedLabels={5}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label>Service Type</label>
                                                                        <MultiSelect value={this.state.serviceFeature}
                                                                            options={this.serviceFeatureTypeList}
                                                                            onChange={this.onServiceFeatureChange}
                                                                            optionLabel="name"
                                                                            placeholder="Select"
                                                                            itemTemplate={this.countryOptionTemplate}
                                                                            maxSelectedLabels={5}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className=" form-control-box">
                                                                            <div>
                                                                                <label className="mb-0">Has 'Try It' functionality?</label>
                                                                            </div>
                                                                            <div className="custom-control custom-switch">
                                                                                <InputSwitch className="filterswitch" checked={this.state.hasTryIt} onChange={(e) => this.setState({ hasTryIt: e.value })} onClick={() => this.setState({ hasTryIt: !this.state.hasTryIt })} />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className=" form-control-box">
                                                                            <div>
                                                                                <label className="mb-0">API Ready</label>
                                                                            </div>
                                                                            <div className="custom-control custom-switch">
                                                                                <InputSwitch className="filterswitch" onClick={() => this.setState({ hasPublished: !this.state.hasPublished })} checked={this.state.hasPublished} onChange={(e) => this.setState({ hasPublished: e.value })} />

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col-6'>
                                                                                <button className="btn btn-outline-danger w-100 filter-btns" onClick={this.clearFilter}>Reset filters</button>

                                                                            </div>
                                                                            <div className='col-6'>
                                                                                {this.state.loading ?
                                                                                    <Loader
                                                                                        type="ThreeDots"
                                                                                        color="#603c96"
                                                                                        height={30}
                                                                                        width={30}
                                                                                    />
                                                                                    :
                                                                                    <button className="btn btn-purple  w-100 filter-btns" onClick={this.searchFromPopver}>Search</button>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }

                                    <BottomScrollListener onBottom={this.onShowmoreClicked} />

                                </div>

                            </div>
                            {
                                this.state.loading ?
                                    null :
                                    <ListingFooter></ListingFooter>
                            }
                        </>
                    }
                </div>
               
          
        );
    }
}

export default hoistStatics((ListingPage), ListingPage);
